import React, { Component } from 'react'
import store from 'store'
import CustomFormValidation from 'helpers/CustomFormValidation'

// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'

// -------------  import page content --------------------

import ContactMainCard   from 'components/contactUs/ContactMainCard.jsx'
import ContactUsForm     from  'components/contactUs/ContactUsForm.jsx'

// -------------  import Api Services ------------------

import Api from 'services/Api.js'

// -------------  import third party libraries --------------------

import WOW from 'wowjs';  /** @WOWJS for animation @version 1.1.3 **/


export default class ContactUs extends Component {

    constructor(props){

        super(props)
        this.state = {
            submitForm: false,
            formData  : {
                captcha: { value : '', error : false, alert:'' },
                query:   { value : '', error : false, alert:'' },
                name:    { value : '', error : false, alert:'' },
                email:   { value : '', error : false, alert:'' },
                subject: { value : '', error : false, alert:'' },
                message: { value : '', error : false, alert:'' },
            }
        }

        this.setValidation = {
            rules :{ 
                captcha  : { require : true },
                query   : { require : true },
                name    : { require : true, minLength : 3, maxLength : 80 },
                email   : { require : true, email : true},
                subject : { require : true, minLength : 3, maxLength : 80 },
                message : { require : true, minLength : 3, maxLength : 80 },
            },
            messages : {
                captcha : { require : 'Please verify that you are not a robot.' },
                query   : { require : 'Please select a query' },
                name    : { require : 'Please enter valid name', minLength : 'Please enter minimum 3 charector', maxLength:'please enter within 80 charector' },
                email   : { require : 'Please enter valid email address', email : 'Not a valid email address' },
                subject : { require : 'Please enter valid subject', minLength : 'Please enter minimum 3 charector',maxLength:'please enter within 120 charector' },
                message : { require : 'Please enter valid message', minLength : 'Please enter minimum 3 charector',maxLength:'please enter within 500 charector' },
            }
        }

        this.api = new Api()
        this.validation = new CustomFormValidation(this.setValidation)
        this.wow = new WOW.WOW({ live: false }).init();

        window.scroll({top: 0, behavior: "smooth"});

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        
    }

    handleChange({ name, value}) {

        const response = this.validation.checkValidation(name,value)

        this.setState({ 
            formData:{ ...this.state.formData,
                [name] :{
                    value : value, 
                    error : response.error, 
                    alert : response.message
                }
            } 
        })
    }

    handleSubmit(event){

        event.preventDefault();

        const { error,state } =  this.validation.checkSubmitValidation(this.state)
        const data = {}

            data.query     = this.state.formData.query.value
            data.email     = this.state.formData.email.value
            data.full_name = this.state.formData.name.value
            data.message   = this.state.formData.message.value
            data.subject   = this.state.formData.subject.value

        this.setState({ formData: state })

        if(error) { console.log('sorry form can not be submited'); return false; }

        this.api.submitContactUs(data).then((response) => {

            if(response.status) {
                if (response.data.statusCode === "200"){
                    store.clearAll()
                    this.props.history.push('/thankyou.html',{ redirectFrom: 'contact-us' })
                }
            }

            else console.log("Error occured while submitting contact-us form");
        
        }).catch((error) => {
            console.log('error occured while calling API')
        })

    }

    render() {
        return (
            <div className="wrapper">
                <section>
                    <Navbar>
                        <Navbar.Header></Navbar.Header>
                        <Navbar.Nav></Navbar.Nav>
                    </Navbar>
                </section>

                <section className="contact-bg">
                    <div className="container">
                        <div className="contact-us ">
                            <div className="row">
                                <ContactMainCard></ContactMainCard>
                                
                                <ContactUsForm 
                                    handleSubmit={this.handleSubmit } 
                                    handleChange={this.handleChange } 
                                    formData={this.state.formData   } 
                                    error={this.validation.error}>
                                </ContactUsForm>
                            </div>
                        </div>
                    </div>
                    <div className="contact-map">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.024219223409!2d88.352380614959!3d22.540765485199163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277186dfb6c0d%3A0xc15c3ccc99ec59!2s8E%2C%20228a%2C%20Acharya%20Jagadish%20Chandra%20Bose%20Rd%2C%20Sreepally%2C%20Elgin%2C%20Kolkata%2C%20West%20Bengal%20700020!5e0!3m2!1sen!2sin!4v1591550573982!5m2!1sen!2sin"
                            width="600" 
                            title="google map" 
                            height="450" 
                            style={{ border: '0' }} 
                            allowFullScreen="" 
                            loading="lazy"
                        />
                    </div>
                </section>

                <section>
                     <Footer></Footer>
                </section>
            </div>
        )
    }
}
