import React from "react";
// -------------  import slider arrows content --------------------

import rightArrowIcon from "icons/home/partners/rightArrow.png";
import leftArrowIcon from "icons/home/partners/leftArrow.png";

import styled from "styled-components";




const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent !important;
  padding: 20px 0;
`;

const SlidesContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 0 5px;
`;



class InfiniteSlider extends React.Component {
  state = {
    cursor: 0,
    jump: false,
  };

  ref = React.createRef();

  componentDidMount() {
    this.ref.current.addEventListener("transitionend", this.onTransitionEnd);
  }

  componentWillUnmount() {
    this.ref.current.removeEventListener("transitioned", this.onTransitionEnd);
  }

  animating = false;

  componentDidUpdate(prevProps, prevState) {
    const { cursor, jump } = this.state;
    const { cursor: oldCursor } = prevState;

    if (cursor !== oldCursor) {
      this.animating = true;
    }

    if (jump) {
      setTimeout(() => {
        this.animating = false;
        this.setState({
          jump: false,
        });
      }, 1);
    }
  }

  onTransitionEnd = () => {
    const { cursor } = this.state;
    const { children } = this.props;
    const count = React.Children.count(children);

    this.animating = false;

    if (cursor >= count) {
      this.setState({
        jump: true,
        cursor: 0,
      });

      return;
    }

    if (cursor <= -1) {
      this.setState({
        jump: true,
        cursor: count - 1,
      });

      return;
    }
  };

  changeCursor = (amount) => {
    const { cursor } = this.state;

    if (this.animating) {
      return;
    }

    this.setState({
      cursor: cursor + amount,
    });
  };

  renderChildren() {
    const { children: childrenElements } = this.props;
    let children = React.Children.toArray(childrenElements);
    children = [].concat(children, children, children);

    return children.map((child, index) => {
      return React.cloneElement(child, { key: index });
    });
  }

  render() {
    const { cursor, jump } = this.state;
    const { children } = this.props;
    const count = React.Children.count(children);

    const style = {
        display: "flex",
        transition: jump ? "none" : "all 200ms ease",
        transform: `translateX(-${(count + cursor) * 150}px)`
      };

    return (
        <Wrapper>
        <img  onClick={() => this.changeCursor(-1)} className="arrow" src={rightArrowIcon} alt="..." />
        <SlidesContainer>
          <div style={style} ref={this.ref}>
            {this.renderChildren()}
          </div>
        </SlidesContainer>
        <img className="arrow" onClick={() => this.changeCursor(1)} src={leftArrowIcon} alt="..." />
        
      </Wrapper>
    );
  }
}

export default InfiniteSlider;
