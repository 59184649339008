/*!
    * This component is used in below pages
    *   -> pages/home.js
    *   -> pages/price.js 
*/

// -------------  import react hooks --------------------

import { useHistory,NavLink } from "react-router-dom";


// -------------  import vectors --------------------

import rightVector from 'icons/home/priceTable/rightVector.png'
import leftVector from 'icons/home/priceTable/leftVector.png'

// -------------  import components --------------------

import PricePlanCard from 'components/cards/PricePlanCard.jsx'

// -------------  import others --------------------

import Plans from 'services/Plans'
import store from 'store';


export default function PriceSection({ illustratorStyle }) {

    const history = useHistory();
    const SubscribePlan = (userSelectedPlan) => {

        store.set('plan_data',userSelectedPlan,new Date().getTime() + (10*60*1000))
        history.push("/signup.html") //Redirect user to signup page ounce user select a plan
    }

    const signUp = () => {

        store.set('plan_data',"FREE_MONTHLY",new Date().getTime() + (10*60*1000))
        //history.push("/signup.html") //Redirect user to signup page ounce user select a plan
        window.location = "/signup.html"
      }

    return (
        <section>
            <img className="price-bg-img" src={rightVector} style={illustratorStyle.left} alt="..." />
            <img className="price-bg-img1" src={leftVector} style={illustratorStyle.right} alt="..." />
            <div className="container">
                <div className="price wow slideInLeft" data-wow-duration="1s" data-wow-delay="1s">
                
                    <div className="web-heading">
                        <h1>Pricing Plans</h1>
                    </div>
                    <div className="price-content">
                        <p><button className="btn btn-group learn" onClick={ () => signUp()}>Sign Up For Free</button></p>
                        <p>Choose the best suitable plan for you as per your need.<br />
                        Not finding yet, what you are looking for.<NavLink to="/contact-us.html" className=""> Get in touch with us now.</NavLink>
                        </p>
                    </div>

                    <div className="tabs">
                        <input type="radio" id='r1' name="r" defaultChecked />
                        <input type="radio" id='r2' name="r" />
                        <input type="radio" id='r3' name="r" />
                        <input type="radio" id='r4' name="r" />
                        <nav style={{width:'50% !important'}}>
                            <ul>
                                <li><label htmlFor='r1'>Monthly</label></li>
                                <li><label htmlFor='r2'>Quaterly</label></li>
                                <li><label htmlFor='r3'>Halfyearly</label></li>
                                <li><label htmlFor='r4'>Annually</label></li>
                            </ul>
                        </nav>

                        <div className="content">
                            <div className="article one">
                                <div className="row center-content">
                                    { Plans.map((plan,index) => <PricePlanCard key={index} data={plan} idx={0} method={SubscribePlan} ></PricePlanCard> ) }
                                </div>
                            </div>
                            <div className="article two">
                                <div className="row center-content">
                                    { Plans.map((plan,index) => <PricePlanCard key={index} data={plan} idx={1} method={SubscribePlan} ></PricePlanCard> ) }
                                </div>
                            </div>
                            <div className="article three">
                                <div className="row center-content">
                                    { Plans.map((plan,index) => <PricePlanCard key={index} data={plan} idx={2} method={SubscribePlan} ></PricePlanCard> ) }
                                </div>
                            </div>
                            <div className="article foor">
                                <div className="row center-content">
                                    { Plans.map((plan,index) => <PricePlanCard key={index} data={plan} idx={3} method={SubscribePlan} ></PricePlanCard> ) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

