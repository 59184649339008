import { Link } from 'react-router-dom'

// -------------  import social media images ------------- 

import faceBook from 'icons/footer/fb.png'
import linkedIn from 'icons/footer/linkedin.png'
import twitter  from 'icons/footer/twitter.png'
import youtube  from 'icons/footer/youtube.png'

import logo from 'icons/footer/logo.png'

const year = new Date().getFullYear()
const Footer = () => {
    return (
        <footer className="site-footer wow slideInLeft" data-wow-duration="1s" data-wow-delay="1s">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12 col-md-3">
                        <img src={logo} className="footer-logo" alt="..." />
                        <p className="text-justifys">(A Division Of PITTER SHARE <br />BROKING PRIVATE LIMITED)</p>
                            <div className="footer-icon">
                                <ul className="social-icons">
                                    <li><a className="facebook" href="https://twitter.com/qodrr_india" target="_blank"><img src={twitter}  alt="..." /></a></li>
                                    <li><a className="twitter"  href="/home"><img src={faceBook} alt="..." /></a></li>
                                    <li><a className="dribbble" href="/home"><img src={youtube}  alt="..." /></a></li>
                                    <li><a className="linkedin" href="/home"><img src={linkedIn} alt="..." /></a></li>
                                </ul>
                            </div>
                    </div>

                        <div className="col-xs-6 col-md-2 w-50">
                            <h6>QUICK LINKS</h6>
                            <ul className="footer-links">
                                <li><Link to="/about-us.html">About Us</Link></li>
                                <li><Link to="/faq.html">FAQs</Link></li>
                                <li><Link to="/contact-us.html">Contact Us</Link></li>
                            </ul>
                        </div>

                        <div className="col-xs-6 col-md-2 w-50">
                            <h6>PRODUCT</h6>
                            <ul className="footer-links">
                                <li><a  target="_blank" rel="noreferrer" href="https://app.qodrr.com/">Log In</a></li>
                                <li><Link to="/pricing.html">Pricing</Link></li>
                                <li><Link to="/affiliate.html">Becomes Affiliate</Link></li>
                            </ul>
                        </div>

                        <div className="col-xs-6 col-md-2 w-50">
                            <h6>LEGAL</h6>
                            <ul className="footer-links">
                                <li><Link to="/privacy-policy.html">Privacy Policy</Link></li>
                                <li><Link to="/term-service.html">Terms of Services</Link></li>
                                <li><Link to="/disclaimer.html">Disclaimer</Link></li>
                            </ul>
                        </div>

                        <div className="col-xs-6 col-md-3 w-50">
                            <h6>CONTACT</h6>
                            <ul className="footer-links">
                                <li><a href="/home">+91 (983) (611)-3375</a></li>
                                <li><a href="/home">support@qodrr.com</a></li>
                                <li><a href="/home">228A, A J C Bose Road,<br />
                                        Landmark 8E Kolkata<br />
                                        (West Bengal),700020 INDIA
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <hr/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-6 col-xs-12">
                            <p className="copyright-text">© Copyright {year}, Qodrr.com
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
    )
}

export default Footer;