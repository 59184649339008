import React, { Component  } from 'react';

// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'
import ResponsiveModal from 'components/modals/ResponsiveModal.jsx'

// -------------  import page content --------------------

import KeyFeaturesSection  from 'components/home/KeyFeaturesSection'
import WorkinSection       from 'components/home/WorkinSection'
import MarketPlaceSection  from 'components/home/MarketPlaceSection'
import ChooseUsSection     from 'components/home/ChooseUsSection'
import PriceSection        from 'components/home/PriceSection'
import PartnersSection     from 'components/home/PartnersSection'
import Diskcard            from 'components/home/Diskcard'

// -------------  import third party libraries --------------------

import WOW from 'wowjs';  /** @WOWJS for animation @version 1.1.3 **/

export default class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {  }
        this.wow = new WOW.WOW({
            live: false
        }).init();
        window.scroll({top: 0, behavior: "smooth"});
    }

    contact(action){
        if(action === 'whatsapp') window.open("https://api.whatsapp.com/send?phone=919836113375&text=I'm%20Interested%20in%20Qodrr%20Services", "_blank");

        if(action === 'call') window.location.href = 'tel:+91 9836113375';

        if(action === 'mail') window.location.href = 'mailto: support@qodrr.com'
    }

    render() { 

        const illustratorStyle = {
            left  : { bottom: '53rem',position: 'absolute' },
            right : { bottom: '53rem',position: 'absolute' }
        }

        return ( 
            <>
                <section>
                    <Navbar>
                        <Navbar.Header method={this.contact}></Navbar.Header>
                        <Navbar.Nav active='Home'></Navbar.Nav>
                        <Navbar.HeaderContent></Navbar.HeaderContent>
                    </Navbar>
                </section>

                <ResponsiveModal></ResponsiveModal>

                <Diskcard method={this.contact}></Diskcard>

                <KeyFeaturesSection></KeyFeaturesSection>

                <WorkinSection></WorkinSection>

                <MarketPlaceSection></MarketPlaceSection>

                <ChooseUsSection></ChooseUsSection>

                <PriceSection illustratorStyle={illustratorStyle}></PriceSection> 

                {/* <PartnersSection></PartnersSection> */}
 
                <section>
                    <Footer></Footer>
                </section>

            </>
         );
    }
}
 
 