import React from 'react'

export default function MarketDetails({data}) {
    return (
        <div className="percent-card">
            <div className="row">
                <div className="col-md-2">
                    <div className="percent-detail right">
                        <p>ROI</p>
                        <span>{ data.rOI }</span>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="percent-detail right">
                        <p>Drawdown</p>
                        <span><i className="fa fa-rupee-sign"></i>{ data.drawdown }</span>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="percent-detail right">
                        <p>Min. Capital</p>
                        <span><i className="fa fa-rupee-sign"></i>{ data.minCapital }</span>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="percent-detail">
                        <p>Monthly Fee</p>
                        <span><i className="fa fa-rupee-sign"></i> { data.monthlyFee } </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
