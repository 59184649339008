import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

export default function ContactUsForm({handleSubmit,error,formData,handleChange}) {
    return (

        <div className="col-md-6">
            <div className="contact-card">
                <div className="card">
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="form-group">
                            <label htmlFor="queryType">QUERY TYPE </label>
                            <select className="form-control" name="query" id="query" onChange={(event) => { handleChange(event.target) }} >
                            <option value="" >Select</option>
                            <option value="Subscription" >Subscription Related Query</option>
                            <option value="Affiliate Programm" >Affiliate Programm Related Query</option>
                            <option value="Need Technical Support" >Need a Technical Support</option>
                            <option value="Custom Algo" >Assistance Required for Custom Algorithms</option>
                            <option value="General Query" >General Query</option>
                            </select>
                            {error(formData.query.alert)}
                        </div>

                        <div className="form-group">
                            <label htmlFor="contact-person-name">YOUR NAME </label>
                            <input className="form-control" id="contact-person-name" name="name"
                                onChange={(event) => { handleChange(event.target) }}  autoComplete="off" placeholder="Type your name" />
                            {error(formData.name.alert)}
                        </div>

                        <div className="form-group">
                            <label htmlFor="contact-person-email">EMAIL ADDRESS </label>
                            <input className="form-control" id="contact-person-email" name="email"
                                onChange={(event) => { handleChange(event.target) }}  autoComplete="off" placeholder="Type your email" />
                            {error(formData.email.alert)}
                        </div>

                        <div className="form-group">
                            <label htmlFor="contact-subject">SUBJECT </label>
                            <input className="form-control" id="contact-subject" name="subject"
                                onChange={(event) => { handleChange(event.target) }} autoComplete="off" placeholder="Type your subject" />
                            {error(formData.subject.alert)}
                        </div>

                        <div className="form-group">
                            <label htmlFor="contact-message">MESSAGE </label>
                            <textarea className="form-control" id="contact-message" name="message"
                                onChange={(event) => { handleChange(event.target) }}  autoComplete="off" placeholder="Type your message here" rows="5"></textarea>
                            {error(formData.message.alert)}
                        </div>

                        
                        <div className="form-group">
                            <ReCAPTCHA sitekey="6Lf7V6kaAAAAAKpDJc1Fy177vNwzOOYZ73t1ze_M" onChange={(value) => handleChange({ name:'captcha',value:value }) } />
                        </div>
                        {error(formData.captcha.alert)}

                        <div className="robo">
                            <div className="send-btn col-md-12">
                                <button className="btn btn-outline-success my-2 my-sm-0 send" type="submit">Send</button>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
