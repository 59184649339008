import React, { Component } from "react";
import { Link } from "react-router-dom";

// -------------  import filxed components -------------

import Navbar from "components/navbar/Navbar.jsx";
import Footer from "components/footer/Footer.jsx";

// -------------  import third party libraries --------------------

import WOW from "wowjs"; /** @WOWJS for animation @version 1.1.3 **/
import store from 'store'

//-------------  import api services --------------------

import Api from 'services/Api'

export default class VerifyPage extends Component {

    constructor(props) {
        super(props);
        this.wow = new WOW.WOW({ live: false }).init();
        this.api = new Api()
        this.checkVerification()

        window.scroll({ top: 0, behavior: "smooth" });
  }

  checkVerification(){

    const search = this.props.location.search;
    const email_token = new URLSearchParams(search).get('email_token');
    const payload = { "email_token": email_token }
        
      this.api.verifyAccount(payload).then((response) => {

        if(response.status){

            if (response.data.statusCode===200) {

                store.clearAll()
                const history = this.props.history
                setTimeout(function(){ history.push("/")},15000);

           } else console.log(response);

        } else console.log('error occured while calling API')

      }).catch((error) => { console.log('error occured while calling API') } )
  }

  render() {
    return (
      <div className="wrapper">
          <section>
              <Navbar>
                  <Navbar.Header></Navbar.Header>
                  <Navbar.Nav></Navbar.Nav>
              </Navbar>
          </section>

          <section className="thank-bg">
              <div className="thanks"><div className="container"><div className="thank-response">
                  <div className="web-heading">
                      <h1>Sign-up successful !!</h1>
                  </div>

                  <p>Thank you for Signing-up with us.Your Account has been Activated.</p>
                  <p>
                      Click here to quickly sign-in to your account?{" "}
                      <a href='https://app.qodrr.com' tw="border-b border-gray-500 border-dotted">Sign In</a>&nbsp; Or &nbsp;
                      <Link to='/contact-us.html' tw="border-b border-gray-500 border-dotted">Contact &nbsp;</Link> 
                      our Support team for any assistance.
                  </p>
              </div></div></div>
          </section>

          <section> 
              <Footer></Footer>
          </section>
      </div>
    );
  }
}
