import React from 'react'

// -------------  import social media images ------------- 

import twitter   from 'images/twitter 1.png';
import facebook  from 'images/fb.png';
import linkedin  from 'images/linkedin.png'

export default function MemberCard({ card }) {
    return (
        <div className="col-md-4">
            <div className="card">
                <div className="team-img">
                    <img src={card.img} className="card-img-top" alt="..."/>
                </div>

                <div className="card-body">

                    <div className="footer-icons">
                        <ul className="social-icons text-center">
                            <li><a className="twitter" href={card.socialMediaLinks.twitter} target={'_blank'}><img src={twitter}  alt="..." /></a></li>
                            <li><a className="facebook"  href={card.socialMediaLinks.facebook} ><img src={facebook} alt="..." /></a></li>
                            <li><a className="linkedin" href={card.socialMediaLinks.linkedin}><img src={linkedin} alt="..." /></a></li>
                        </ul>
                    </div>
                    
                    <h5 className="card-title">{ card.designation }</h5>

                    <span>{ card.name }</span>
                    
                    <p className="card-text"> { card.about } </p>

                </div>
            </div>
        </div>
    )
}
