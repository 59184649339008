const defaultPlans = [
    {
      name: "Starter Pack",
      userSelectePlans:["STARTER_MONTHLY","STARTER_QUATERLY","STARTER_HALFYEARLY","STARTER_YEARLY"],
      planDurations: ["Monthly","Quaterly","Halfyearly","Year"],
      durationPrices: ["250", "700", "1250", "2250"],
      mainFeature: "For Entry Level",
      features: ["Build Strategy : Unlimited", "Segment Access : Cash,Future",
      "Backtesting Strategy: Unlimited with 1 month backdata",
      "Paper trading : 1 Strategy","Auto Trading: No Access",
      "Notification Trading: No Access","Pro Screener: Full Access",
      "Marketplace Access : Allowed","Public (Paid) Strategy Creation : Not Allowed",
      "Custom Strategy Guidence : No","Support: Email"
      ],
    },
    {
      name: "Smart Trader",
      userSelectePlans:["SMART_MONTHLY","SMART_QUATERLY","SMART_HALFYEARLY","SMART_YEARLY"],
      planDurations: ["Monthly","Quaterly","Halfyearly","Year"],
      durationPrices: ["750", "2000", "3500", "6500"],
      mainFeature: "Self Starter For Least Active Users",
      features: ["Build Strategy : Unlimited", "Segment Access : Cash,Future",
      "Backtesting Strategy: Unlimited with 3 month backdata",
      "Paper trading : 5 Strategies","Auto Trading: 5 Strategies",
      "Notification Trading: 5 Strategies","Pro Screener: Full Access",
      "Marketplace Access : Allowed","Public (Paid) Strategy Creation : Not Allowed",
      "Custom Strategy Guidence : No","Support: Email"
      ],
      featured: true
    },
    {
      name: "Master Trader",
      userSelectePlans:["MASTER_MONTHLY","MASTER_QUATERLY","MASTER_HALFYEARLY","MASTER_YEARLY"],
      planDurations: ["Monthly","Quaterly","Halfyearly","Year"],
      durationPrices: ["2250", "6000", "11000", "20000"],
      mainFeature: "Self Starter For Active Users",
      features: ["Build Strategy : Unlimited", "Segment Access : Cash,Future",
      "Backtesting Strategy: Unlimited with 12 month backdata",
      "Paper trading : 10 Strategies","Auto Trading: 10 Strategies",
      "Notification Trading: 10 Strategies","Pro Screener: Full Access",
      "Marketplace Access : Allowed","Public (Paid) Strategy Creation : Allowed",
      "Custom Strategy Guidence : Yes","Support: Email,Call Support"
     ],
    },
    {
      name: "Wizard Trader",
      userSelectePlans:["WIZARD_MONTHLY","WIZARD_QUATERLY","WIZARD_HALFYEARLY","WIZARD_YEARLY"],
      planDurations: ["Monthly","Quaterly","Halfyearly","Year"],
      durationPrices: ["5000", "12500", "22500", "40000"],
      mainFeature: "PRO Strategies Execution",
      features: ["Build Strategy : Unlimited", "Segment Access : Cash,Future",
       "Backtesting Strategy: Unlimited with 36 month backdata",
       "Paper trading : 25 Strategies","Auto Trading: 25 Strategies",
       "Notification Trading: 25 Strategies","Pro Screener: Full Access",
       "Marketplace Access : Allowed","Public (Paid) Strategy Creation : Allowed",
       "Custom Strategy Guidence : Yes","Support: Email,Call Support"
      ]
    }
  ];

  export default defaultPlans