import React, { Component } from 'react'
import { Link } from 'react-router-dom'
 
// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'

// -------------  import page content --------------------

import Content from 'components/thankYou/Content.jsx';

// -------------  import third party libraries --------------------

import WOW from 'wowjs';  /** @WOWJS for animation @version 1.1.3 **/

export default class ThankYou extends Component {

    constructor(props) {
        super(props)
        this.wow = new WOW.WOW({ live: false }).init();
        window.scroll({top: 0, behavior: "smooth"});
    }

    render() {

        let ShowContent = Content.emptyDiv  

        if(this.props.location.state === undefined) this.props.history.push('/', {})

        else if(!this.props.location.state.hasOwnProperty('redirectFrom')) this.props.history.push('/', {})

        else if(this.props.location.state.redirectFrom === 'contact-us') ShowContent = Content.ContactUs;

        else if(this.props.location.state.redirectFrom === 'sign-up') ShowContent = Content.SignUp

        else this.props.history.push('/', {})

        return (
            <div className="wrapper">
                <section>
                    <Navbar>
                        <Navbar.Header></Navbar.Header>
                        <Navbar.Nav></Navbar.Nav>
                    </Navbar>
                </section>

                <section className="thank-bg">
                    <div className="thank">
                        <div className="container">
                            <div className="thank-mess">


                                <ShowContent></ShowContent>
                               
                                <Link to="/" className="btn btn-outline-success my-2 my-sm-0 send" type="submit">
                                    Back to Home
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <Footer></Footer>
                </section>
            </div>
        )
    }
}

