import React from 'react'

export default function SearchFilters() {
    return (
       
            <div className="filter-btns">
                <button className="btn btn-group filter-btn">Sort by <i className="fa fa-sort-alt"></i></button>
                <button className="btn btn-group filter-btn">
                    Sort by Rating 
                    <i className="fa fa-star"></i>
                </button>
                <button className="btn btn-group filter-btn">
                    Pricing 
                    <i className="fa fa-rupee-sign"></i>
                </button>
                <button className="btn btn-group">
                    <div className="form-group fliter-btn">
                        <select className="form-control" id="exampleFormControlSelect1">
                            <option>Tags</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </button>
                <button className="btn btn-group filter-btn">Filters <i
                    className="fa fa-sliders-h"></i></button>
            </div>
    )
}
