import React from "react";
import Tick from "images/tick.png";
export default function PricePlanCard({ data, idx, method }) {
  return (
    <div className="col-md-3">
      <div className="market-place-card">
        <div className="card">
          <div className="market-inner-card">
            <div className="con-2">
              {/* <h5><i className="fa fa-rupee-sign"></i> 42500</h5> */}
              <h3>
                <i className="fa fa-rupee-sign"></i> {data.durationPrices[idx]}/
                {data.planDurations[idx]}
              </h3>
              <h6>Additional 18% GST</h6>
              <span>{data.name}</span>
              <p>{data.mainFeature}</p>
            </div>
            <div className="market-place-content">
              <span>Features/Plans</span>

              {   data.features.map((feature, index) => (
                        <div className="tick col-md-12" key={index}>
                            <div className="row">
                                <div className="col-md-3 mobile-tick" style={{textAlign:'right'}}>
                                    <img src={Tick} alt="..." />
                                </div>
                                <div className="col-md-9 tick-mobile">
                                    <p key={index}>{feature}</p>
                                </div>
                            </div>
                        </div>
                  ))
              }
            </div>
            <div className="market-btn">
              <button
                className="btn btn-group table-btn"
                onClick={() => method(data.userSelectePlans[idx])}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
