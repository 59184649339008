import React, { Component } from 'react'

// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'

// -------------  import page content --------------------

import DisclaimerContent from 'components/disclaimer/DisclaimerContent.jsx'

// -------------  import third party libraries --------------------

import WOW from 'wowjs';  /** @WOWJS for animation @version 1.1.3 **/

export default class Disclaimer extends Component {
    
    constructor(props){
        super(props);
        this.wow = new WOW.WOW({ live: false }).init();
        
        window.scroll({top: 0, behavior: "smooth"});
    }

    render() {
        return (
            <div className="wrapper">
                <section>
                    <Navbar>
                        <Navbar.Header></Navbar.Header>
                        <Navbar.Nav active='Term-service'></Navbar.Nav>
                    </Navbar>
                </section>
                <section>
                    <DisclaimerContent></DisclaimerContent>
                </section>
                <section>
                    <Footer></Footer>
                </section>
            </div> 
        )
    }
}
