import React from 'react'
import Illustration from 'images/Algo trading Illustration.png'

export default function AlgoTradingPlatform() {
    return (
        <div className="row mt-2 mb-2 reverse-row">
            <div className="col-md-6 mt-auto mb-auto" >
                <div className="service-content">
                    <div className="service-heading">
                        <h1>Algo Trading Platform</h1>
                    </div>
                    <div className="service-content">
                        <p>We offer secure and reliable platform to <br />
                            automate your trading strategy.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6 ">
                <div className="service-img">
                    <img src={Illustration} alt="..." />
                </div>
            </div>
        </div>
    )
}
