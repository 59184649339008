const data = [
    {
        heading: 'Solid Trend One ',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'26.85%',
        drawdown: '20.2 K (5%)',
        minCapital : '220,000',
        monthlyFee : '700 + 5%'
    },
    {
        heading: 'Solid Trend Two',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'96.85%',
        drawdown: '18.2 K (5%)',
        minCapital : '320,000',
        monthlyFee : '800 + 5%'
    },
    {
        heading: 'Solid Trend Three',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'36.85%',
        drawdown: '16.2 K (5%)',
        minCapital : '420,000',
        monthlyFee : '900 + 5%'
    },
    {
        heading: 'Solid Trend Four',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'26.85%',
        drawdown: '20.2 K (5%)',
        minCapital : '220,000',
        monthlyFee : '700 + 5%'
    },
    {
        heading: 'Solid Trend Five',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'96.85%',
        drawdown: '18.2 K (5%)',
        minCapital : '320,000',
        monthlyFee : '800 + 5%'
    },
    {
        heading: 'Solid Trend 6',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'36.85%',
        drawdown: '16.2 K (5%)',
        minCapital : '420,000',
        monthlyFee : '900 + 5%'
    },
    {
        heading: 'Solid Trend 7',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'26.85%',
        drawdown: '20.2 K (5%)',
        minCapital : '220,000',
        monthlyFee : '700 + 5%'
    },
    {
        heading: 'Solid Trend 8',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'96.85%',
        drawdown: '18.2 K (5%)',
        minCapital : '320,000',
        monthlyFee : '800 + 5%'
    },
    {
        heading: 'Solid Trend 9',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'36.85%',
        drawdown: '16.2 K (5%)',
        minCapital : '420,000',
        monthlyFee : '900 + 5%'
    },
    {
        heading: 'Solid Trend 10',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'26.85%',
        drawdown: '20.2 K (5%)',
        minCapital : '220,000',
        monthlyFee : '700 + 5%'
    },
    {
        heading: 'Solid Trend 11',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'96.85%',
        drawdown: '18.2 K (5%)',
        minCapital : '320,000',
        monthlyFee : '800 + 5%'
    },
    {
        heading: 'Solid Trend 12',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'36.85%',
        drawdown: '16.2 K (5%)',
        minCapital : '420,000',
        monthlyFee : '900 + 5%'
    },
    {
        heading: 'Solid Trend 13',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'26.85%',
        drawdown: '20.2 K (5%)',
        minCapital : '220,000',
        monthlyFee : '700 + 5%'
    },
    {
        heading: 'Solid Trend 14',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'96.85%',
        drawdown: '18.2 K (5%)',
        minCapital : '320,000',
        monthlyFee : '800 + 5%'
    },
    {
        heading: 'Solid Trend 15',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'36.85%',
        drawdown: '16.2 K (5%)',
        minCapital : '420,000',
        monthlyFee : '900 + 5%'
    },
    {
        heading: 'Solid Trend 16',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'26.85%',
        drawdown: '20.2 K (5%)',
        minCapital : '220,000',
        monthlyFee : '700 + 5%'
    },
    {
        heading: 'Solid Trend 17',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'96.85%',
        drawdown: '18.2 K (5%)',
        minCapital : '320,000',
        monthlyFee : '800 + 5%'
    },
    {
        heading: 'Solid Trend 18',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'36.85%',
        drawdown: '16.2 K (5%)',
        minCapital : '420,000',
        monthlyFee : '900 + 5%'
    },
    {
        heading: 'Solid Trend 19',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'26.85%',
        drawdown: '20.2 K (5%)',
        minCapital : '220,000',
        monthlyFee : '700 + 5%'
    },
    {
        heading: 'Solid Trend 20',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'96.85%',
        drawdown: '18.2 K (5%)',
        minCapital : '320,000',
        monthlyFee : '800 + 5%'
    },
    {
        heading: 'Solid Trend 21',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'36.85%',
        drawdown: '16.2 K (5%)',
        minCapital : '420,000',
        monthlyFee : '900 + 5%'
    },
    {
        heading: 'Solid Trend 22',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'26.85%',
        drawdown: '20.2 K (5%)',
        minCapital : '220,000',
        monthlyFee : '700 + 5%'
    },
    {
        heading: 'Solid Trend 23',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'96.85%',
        drawdown: '18.2 K (5%)',
        minCapital : '320,000',
        monthlyFee : '800 + 5%'
    },
    {
        heading: 'Solid Trend 24',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'36.85%',
        drawdown: '16.2 K (5%)',
        minCapital : '420,000',
        monthlyFee : '900 + 5%'
    },{
        heading: 'Solid Trend 25',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'26.85%',
        drawdown: '20.2 K (5%)',
        minCapital : '220,000',
        monthlyFee : '700 + 5%'
    },
    {
        heading: 'Solid Trend 26',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'96.85%',
        drawdown: '18.2 K (5%)',
        minCapital : '320,000',
        monthlyFee : '800 + 5%'
    },
    {
        heading: 'Solid Trend Three',
        subheading: 'HEG (NSE)',
        tags: ['NFO','TrendFollowing','Volatility','Pairs','Bearish','Bullish','Directional','MarketNeutral'],
        rOI:'36.85%',
        drawdown: '16.2 K (5%)',
        minCapital : '420,000',
        monthlyFee : '900 + 5%'
    }
]

export default data;