import React, { Component } from "react";
import { Link } from "react-router-dom";

// -------------  import background images -------------

import backgroundDesignTwo from "images/line graph.png";
import backgroundDesignThree from "images/Partner with us Illustration.png";

// -------------  import filxed components -------------

import Navbar from "components/navbar/Navbar.jsx";
import Footer from "components/footer/Footer.jsx";

// -------------  import third party libraries --------------------

import WOW from "wowjs"; /** @WOWJS for animation @version 1.1.3 **/

export default class Affiliate extends Component {
  constructor(props) {
    super(props);
    
    this.state = {};
    this.wow = new WOW.WOW({ live: false }).init();

    window.scroll({top: 0, behavior: "smooth"});
  }

  render() {
    return (
      <div className="wrapper">
        <section>
          <Navbar>
            <Navbar.Header></Navbar.Header>
            <Navbar.Nav active="Become Affiliate"></Navbar.Nav>
          </Navbar>
        </section>

        <section>
          <img src={backgroundDesignTwo} className="partner-bg" alt="..." />
          <div className="container">
            <div className="partner">
              <div className="row reverse-row" >
                <div className="col-md-6">
                  <div className="web-heading-ab">
                    <h1>PARTNER WITH US</h1>
                    <span>Become Affiliate</span>
                    <p>
                      Become an associate. Avail of benefits, rewards, cash
                      backs and more. Just do your part, refer us to your
                      friends & clients and if they subscribe, we will make sure
                      that you get your share and there’s always possibilities
                      to earn more.
                    </p>
                    <Link className="btn btn-group offer" to="/contact-us.html">
                      Contact Now
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-img">
                    <img src={backgroundDesignThree} alt="..." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <Footer></Footer>
        </section>
      </div>
    );
  }
}
