import React, { Component } from "react";


// -------------  import filxed components -------------

import Navbar from "components/navbar/Navbar.jsx";
import Footer from "components/footer/Footer.jsx";

// -------------  import page content --------------------

import Content from 'components/thankYou/Content.jsx';

// -------------  import third party libraries --------------------

import WOW from "wowjs"; /** @WOWJS for animation @version 1.1.3 **/
import store from 'store'

//-------------  import api services --------------------

import Api from 'services/Api'

export default class PaymentResponse extends Component {
    constructor(props) {
        super(props);

        this.wow = new WOW.WOW({  live: false, }).init();

        this.api = new Api()
        this.search = this.props.location.search
        this.paymentRequestId = new URLSearchParams(this.search).get('payment_request_id');
        this.paymentId        = new URLSearchParams(this.search).get('payment_id');
        this.paymentStatus    = new URLSearchParams(this.search).get('payment_status');

      window.scroll({ top: 0, behavior: "smooth" });

  }


  getPaymentStatus(){

      const planType = store.get("plan_data")
      const userData = store.get('user_data')
      const payload = {
          "username"       : userData.username,
          "email"          : userData.email,
          "payment_req_id" : this.paymentRequestId,
          "payment_id"     : this.paymentId,
          "payment_status" : this.paymentStatus,
          "plan_type"      : planType
        }

        this.api.paymentStatus(payload).then((response) => {

          if(response.status) {
            
              if (response.data.statusCode === '200') {
               store.clearAll()
               const history = this.props.history
               setTimeout(function(){ history.push("/") },15000);

            }   else console.log(response.data);
          }  else console.log(response);

        }).catch((error) => { console.log('error occured while calling API') })
  }

  render() {

    let ShowContent = Content.emptyDiv    

      if(store.get("user_data") === undefined || store.get('user_data') === '') ShowContent = Content.MemberShipError

      else if(store.get('user_data').username === undefined || store.get('user_data').username === '')  ShowContent = Content.MemberShipError

      else if(store.get('user_data').email   === undefined || store.get('user_data').email === '') ShowContent = Content.MemberShipError

      else if(store.get('plan_data') === undefined || store.get('plan_data') === '') ShowContent = Content.MemberShipError

      else { ShowContent = Content.MemberSuccess;   this.getPaymentStatus()   }
      
      

    return (
      <div className="wrapper">
          <section>
              <Navbar>
                  <Navbar.Header></Navbar.Header>
                  <Navbar.Nav></Navbar.Nav>
              </Navbar>
          </section>

          <section className="thank-bg">
              <div className="thank" style={{ marginTop: '0em' }}>
                  <div className="container">
                      <ShowContent paymentId={ this.paymentId } paymentStatus={ this.paymentStatus } ></ShowContent>
                  </div>
              </div>
          </section>

        <section>
          <Footer></Footer>
        </section>
      </div>
    );
  }
}

// http://localhost:3000/payment-response.html?payment_id=MOJO1c20R05A12678207&payment_status=Credit&payment_request_id=c39aa297de0540499b51dcabd170e44a
// http://localhost:3000/payment-response.html?payment_id=MOJO1c20I05A12678222&payment_status=Credit&payment_request_id=da0fa8ea5cfb4c67b13f30bc98a30936