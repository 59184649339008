import React from 'react'
import Illustration from 'images/premium strategies ill.png'

export default function PremiumStrategies() {
    return (
        <div className="row mt-2 mb-2 reverse-row">
            <div className="col-md-6 mt-auto mb-auto" >
                <div className="service-content">
                    <div className="service-heading">
                        <h1>Premium Strategies</h1>
                    </div>
                    <div className="service-content">
                        <p>Build your wealth with our expert strategies.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="service-img">
                    <img src={Illustration} alt="..." />
                </div>
            </div>
        </div>
    )
}
