import React, { Component } from 'react'

// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'

// -------------  import page content --------------------

import SearchFilters from 'components/marketPlacePrices/SearchFilters.jsx'
import Card  from 'components/marketPlacePrices/Card.jsx'
import ReactPaginate from 'react-paginate';


// -------------  import third party libraries --------------------

import WOW from 'wowjs';  /** @WOWJS for animation @version 1.1.3 **/

// ------------- import static data -------------

import data from "helpers/MarketPlaceData.js"

export default class MarketPlacePrices extends Component {

    constructor(props){
        super(props)

        this.wow = new WOW.WOW({ live: false }).init();
        this.state = { currentCards : data.slice(0, 4) }

        this.pagination = {
            data         : data,
            offset       :  0,
            endOffset    :  4,
            itemsPerPage :  4,  
            totalCards   :  Math.ceil(data.length / 4)
         }

        this.handlePageClick = this.handlePageClick.bind(this)

        window.scroll({top: 0, behavior: "smooth"});
    }

    handlePageClick(event){

        this.pagination.offset = (event.selected * this.pagination.itemsPerPage) % this.pagination.data.length;
        this.pagination.endOffset = this.pagination.offset + this.pagination.itemsPerPage;

        this.setState({ currentCards :  this.pagination.data.slice(this.pagination.offset, this.pagination.endOffset) })
        window.scroll({top: 0, behavior: "smooth"});
    }

    render() {
        return (
            <div className="wrapper">
                <section>
                    <Navbar>
                        <Navbar.Header></Navbar.Header>
                        <Navbar.Nav></Navbar.Nav>
                    </Navbar>
                </section>

                <section className="service-bg">
                    <div className="m-place">
                        <div className="container">
                            <div className="mp-place">
                                <div className="web-heading">
                                    <h1>Marketplace</h1>
                                </div>

                                <div className="search">
                                    <input type="text" className="searchTerm" placeholder="Search..." />
                                    <button type="submit" className="searchButton">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>

                                <div className="market-filter">
                                    <SearchFilters></SearchFilters>
                                </div>

                                <div className="market-place-card"><div className="market-cards"> 
                                        <div className="row">
                                            { this.state.currentCards.map((card,index) => <Card data={card} key={index}></Card>) }
                                        </div>

                                        <div className="row"><div className="col-md-12">
                                            <ReactPaginate className="react-pagination" breakLabel="..." nextLabel="next"  onPageChange={this.handlePageClick}  pageRangeDisplayed={5} pageCount={this.pagination.totalCards}  previousLabel="previous" renderOnZeroPageCount={null} />
                                        </div></div>
                                </div></div>

                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <Footer></Footer>
                </section>
            </div>
        )
    }
}
