import React from "react";


const Diskcard = () => {
  return (
    <section className="back-disk">
      <div className="container">
        <div className="disk-main">
          <div className="row">
            <div className="col-md-4">
              <div className="disk-card">
                <div className="disk-head">
                  <h4>Discover Top Strategies</h4>
                </div>
                <a href="https://app.qodrr.com/dashboard">
                  <div className="card">
                    <div className="one"></div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="disk-card">
                <div className="disk-head">
                  <h4>Build, Test and Trade</h4>
                </div>
                <a href="https://app.qodrr.com/dashboard">
                  <div className="card">
                    <div className="two"></div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="disk-card">
                <div className="disk-head">
                  <h4>Real Time Live Screens</h4>
                </div>
                <a href="https://app.qodrr.com/dashboard">
                  <div className="card">
                    <div className="three"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="social-btn">
            <div className="buttons">
              <div className="what space">
                <button className="btn btn-group" onClick={ () => method('whatsapp')}>
                  <img src={what} />
                </button>
              </div>
              <div className="mail space">
                <button className="btn btn-group" onClick={ () => method('mail')}>
                  <img src={mail} />
                </button>
              </div>
              <div className="call space">
                <button className="btn btn-group" onClick={ () => method('call')}>
                  <img src={call} />
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Diskcard;
