
import React, { Component } from 'react'

// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'

// -------------  import page section --------------------

import PriceSection from 'components/home/PriceSection.jsx'

// -------------  import third party libraries --------------------

import WOW from 'wowjs';  /** @WOWJS for animation @version 1.1.3 **/

export default class Price extends Component {

    constructor(){
        super()

        this.wow = new WOW.WOW({ live: false }).init();
        this.illustratorStyle = {
            left: {
                bottom:'29em', position:'absolute'
            },
            right: {
                bottom:'27em', position:'absolute'
            }
        }

        window.scroll({top: 0, behavior: "smooth"});
    }
    render() {

        return (
            
            <div className="wrapper">
                <section>
                    <Navbar>
                        <Navbar.Header></Navbar.Header>
                        <Navbar.Nav active='Pricing'></Navbar.Nav>
                    </Navbar>
                </section>

                <section>
                    <PriceSection illustratorStyle={this.illustratorStyle}></PriceSection>
                </section>
                
                <section>
                    <Footer></Footer>
                </section>
            </div>
        )
    }
}
