
// -------------  import slider images --------------------

import ellipseOne   from 'icons/home/partners/Ellipse 14.png'
import ellipseTwo   from 'icons/home/partners/Ellipse 15.png'
import ellipseThree from 'icons/home/partners/Ellipse 16.png'
import ellipseFour  from 'icons/home/partners/Ellipse 17.png'
import ellipseFive  from 'icons/home/partners/Ellipse 19.png'

// -------------  import other component --------------------

import InfiniteSlider from 'components/slider/InfiniteSlider'

const PartnersSection = () => {

    const images = [
        ellipseOne,
        ellipseTwo,
        ellipseThree,
        ellipseFour,
        ellipseFive,
    ]

    return (
        <section>
            <div className="partner wow slideInRight" data-wow-duration="1s" data-wow-delay="1s">
                <div className="container">
                    <div className="web-heading">
                        <h1>Our Partners</h1>
                    </div>
                    <div className="partner-slider">
                        <div id="container">
                           
                            <InfiniteSlider>
                                {
                                    images.map((image,index) =>{
                                        return (
                                            <div className="slide" key={index}>
                                                <span><img src={image} alt="..." /></span>
                                            </div>
                                        )
                                    })
                                }
                            </InfiniteSlider>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PartnersSection;