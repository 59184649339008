import validator from 'validator'
import parsePhoneNumber from 'libphonenumber-js'


class CustomFormValidation {
   constructor(validation){
       this.country = "IN"
       this.validation = validation || {
           rules: {},messages: {},
       }
    }

    nullValidator(value,typeOfValidation,property){

        if(String(value) === "") return { 
            message : this.validation.messages[property][typeOfValidation],
            error   : true 
        }

        return { error: false ,message: '' }
    }

   minLengthValidator(value,typeOfValidation,property){

        if(value.length < this.validation.rules[property][typeOfValidation]) return { 
            message : this.validation.messages[property][typeOfValidation],
            error   : true 
        }

        return { error: false ,message: '' }
   }

   maxLengthValidator(value,typeOfValidation,property){

        if(value.length > this.validation.rules[property][typeOfValidation]) return { 
            message : this.validation.messages[property][typeOfValidation],
            error   : true 
        }

        return { error: false ,message: '' }
    }

    numericValidator(value,typeOfValidation,property){

        if(!validator.isNumeric(String(value))) return { 
            message : this.validation.messages[property][typeOfValidation],
            error   : true 
        }

      return { error: false ,message: '' }
    }

    contactNumberValidator(value,typeOfValidation,property){

        const phoneNumber = parsePhoneNumber(String(value))

        if(phoneNumber === undefined){
            return { 
                message : this.validation.messages[property][typeOfValidation],
                error   : true 
            }
        }

        else if(!phoneNumber.isValid() === true) return { 
                message : this.validation.messages[property][typeOfValidation],
                error   : true 
            }

        return { error: false ,message: '' }
    }

    emailValidator(value,typeOfValidation,property){

        if(!validator.isEmail(String(value))) return { 
            message : this.validation.messages[property][typeOfValidation],
            error   : true 
        }

      return { error: false ,message: '' }
   }
   

   checkValidation(name,value){

       try {
           
            if(name in this.validation.rules){

                for (const property in this.validation.rules[name]) {
                
                    if(property === 'require'){
                     
                        const {error,message} = this.nullValidator(value,property,name)

                            if(error) throw message
                    }

                    else if(property === 'email'){

                        const {error,message} = this.emailValidator(value,property,name)
    
                        if(error) throw message
                    }

                    else if(property === 'number'){

                        const {error,message} = this.numericValidator(value,property,name)
    
                        if(error) throw message
                    }

                    else if(property === 'phoneNumber'){

                        const {error,message} = this.contactNumberValidator(value,property,name)
    
                        if(error) throw message
                    }

                    else if(property === 'minLength'){

                        const {error,message} = this.minLengthValidator(value,property,name)
    
                        if(error) throw message
                    }

                    else if(property === 'maxLength'){

                        const {error,message} = this.maxLengthValidator(value,property,name)
    
                        if(error) throw message
                    }
                }
            }

       } catch (error) { return { error:true,message: error } }

       return { message: '' , error: false }
   }

   checkSubmitValidation({ formData }) {
    
        const validationResponse = {
            state : formData,
            error : false
        }

        try {
            
            for (const property in formData) {

                const response = this.checkValidation(property,formData[property]['value'])
     
                formData[property].alert = response.message
                formData[property].error = response.error

                if(response.error) validationResponse.error = response.error
            }

        } catch (error) {
           console.clear(); console.log('error occurred') 
        }

      return validationResponse
   }

   error(errorMessage) {
        return errorMessage === ""  ? <></> : (
            <span className="text-danger">
                <i className="fas fa-exclamation-triangle"></i>&nbsp;
                { errorMessage }
            </span>
        )
    }
}

export default CustomFormValidation;