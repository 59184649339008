import React from 'react'

export default function Accordion({ data,index }) {

    const target = '#collapse-' + index
    const id     = 'collapse-' + index

    return (
        
        <div className="accordance" key={index}>
            <div className="accordion" id="accordionExample">
                <div className="card">
                    <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                            <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target={target} aria-expanded="true" aria-controls={id}>
                                <span> { data.question } </span>
                                <p><i className="fa fa-angle-down"></i></p>
                            </button>
                        </h2>
                    </div>
                    <div id={id} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body">
                            { data.answer }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
