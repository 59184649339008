import React, { Component } from 'react'

// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'

// -------------  import page content --------------------

import AlgoTradingPlatform  from 'components/services/AlgoTradingPlatform'
import AlgoStrategyGuidance from 'components/services/AlgoStrategyGuidance'
import BackTestStrategy     from 'components/services/BackTestStrategy'
import ShareAndSubscribe    from 'components/services/ShareAndSubscribe'
import PremiumStrategies    from 'components/services/PremiumStrategies'
import ExpertAdvice         from 'components/services/ExpertAdvice'

// -------------  import third party libraries --------------------

import WOW from 'wowjs';  /** @WOWJS for animation @version 1.1.3 **/


export default class Services extends Component {
    
    constructor(props){
        super(props)
        this.wow = new WOW.WOW({ live: false }).init();

        window.scroll({top: 0, behavior: "smooth"});
    }

    render() {
        return (
            <div className="wrapper">
                <section>
                    <Navbar>
                        <Navbar.Header></Navbar.Header>
                        <Navbar.Nav active='Services'></Navbar.Nav>
                    </Navbar>
                </section>
                
                <section className="service-bg">
                    <div className="service"><div className="container">
                        <AlgoTradingPlatform></AlgoTradingPlatform>
                        <AlgoStrategyGuidance></AlgoStrategyGuidance>
                        <BackTestStrategy></BackTestStrategy>
                        <ShareAndSubscribe></ShareAndSubscribe>
                        <PremiumStrategies></PremiumStrategies>
                        <ExpertAdvice></ExpertAdvice>
                    </div></div>
                </section>
                
                <section>
                    <Footer></Footer>
                </section>
            </div>
        )
    }
}
