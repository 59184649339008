import React from 'react'
import { Link } from 'react-router-dom'

import aboutIllustration  from 'images/About Illustration.png'

export default function AboutCard() {
    return (
        <div className="row reverse-row">

            <div className="col-md-6"><div className="web-heading-ab">
                <h1>ABOUT QODRR.COM</h1>
                <span>We are here to share market wisdom</span>
                <p>An experienced team with a cumulative experience of 50+
                    years in the Indian stock market and broking industry. We
                    have a great team of technology-driven and enthusiastic
                    passionate technical analysts.
                </p>
                <Link className="btn btn-group offer" to="/pricing.html">Check our offering</Link>
            </div></div>

            <div className="col-md-6">
                <div className="about-img">
                    <img src={aboutIllustration} alt="..." />
                </div>
            </div>

        </div>
    )
}
