import {NavLink } from "react-router-dom";

// -------------  import background images -------------

import leftIllustrator from "images/BG Design2.png";
import backgroundDesignThree from "images/BG Design.png";
import backgroundDesignFour from "images/illustration 1.png";
import what from "../../icons/home/what.png";
import mail from "../../icons/home/mail.png";
import call from "../../icons/home/call.png";

import Logo from "images/logo.png";
import React from "react";
import store from 'store';

// -------------  navbar links -------------

// const navBarNavLi = [
//     {
//         location: '/about.html',
//         text: 'About'
//     },
//     {
//         location: '/services.html',
//         text: 'Services'
//     },
//     {
//         location: '/price.html',
//         text: 'Pricing'
//     },
//     {
//         location: '/market-place.html',
//         text: 'Marketplace'
//     },
//     {
//         location: '/affiliate.html',
//         text: 'Become Affiliate'
//     }
// ]


const contact = (action) => {
  if(action === 'whatsapp') window.open("https://api.whatsapp.com/send?phone=919836113375&text=I'm%20Interested%20in%20Qodrr%20Services", "_blank");

  if(action === 'call') window.location.href = 'tel:+91 9836113375';

  if(action === 'mail') window.location.href = 'mailto: support@qodrr.com'
}

const signUp = () => {

  store.set('plan_data',"FREE_MONTHLY",new Date().getTime() + (10*60*1000))
  //history.push("/signup.html") //Redirect user to signup page ounce user select a plan
  window.location = "/signup.html"
}

const navBarNavLi = [
  {
    location: "/pricing.html",
    text: "Discover",
  },
  {
    location: "/pricing.html",
    text: "Build",
  },
];

const Nav = ({ active }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark ">
      <NavLink className="navbar-brand" to="/">
        <div className="logo">
          <img src={Logo} alt="..." />
        </div>
      </NavLink>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          {navBarNavLi.map((li, index) => {
            const classList =
              li.text === active ? "nav-link active-link" : "nav-link";

            return (
              <li className="nav-item active" key={index}>
                <NavLink className={classList} to={li.location}>
                  {li.text}
                  <span className="sr-only">(current)</span>
                </NavLink>
              </li>
            );
          })}
        </ul>

        <a
          href="https://app.qodrr.com"
          className="btn btn-outline-success my-2 my-sm-0 login mr-2"
          type="submit"
        >
          Sign In
        </a>
        {/* <NavLink to="/contact-us.html" className="btn btn-outline-success my-2 my-sm-0 touch" type="submit">Get in Touch</NavLink> */}
      </div>
    </nav>
  );
};

const HeaderContent = ({
  method
}) => {
  return (
    <div className="header-content">
      {/* <div className="top-header-content wow slideInLeft" data-wow-duration="1s" data-wow-delay="1s">
                <h2>Make Your Trading Journey Automated</h2>
                <h4>Robust & Rewarding</h4>
                <span>
                    We are team of well blended mix of market experienced
                    and technology driven enthusiastic people.
                </span>

                <div className="header-btn mt-3">
                    <div className="row">
                        <div className="col-md-12">
                            <button className="btn btn-group start">Get Started</button>
                            <button className="btn btn-outline-success my-2 my-sm-0 work" data-toggle="modal" data-target="#exampleModal">How it works</button>
                        </div>
                    </div>
                </div>
            </div> */}

      {/* <div className="top-header-img">
                <img src={backgroundDesignThree} alt="..." />
            </div>

            <div className="top-illustration">
                <img src={backgroundDesignFour} alt="..." />
            </div> */}
      <div className="top-head-content">
        <h2>Click to Algo</h2>
        <h2>get Quants on <span>Demand, Realtime, Rewarding</span></h2>
      </div>
      <button className="btn btn-group learn" onClick={ () => signUp()}>Sign Up For Free</button>
      
      {/**<button className="btn btn-outline-success my-2 ml-4 my-sm-0 works" data-toggle="modal" data-target="#exampleModal">How it works</button> */}
    </div>
  );
};

const Header = () => {
  return (
    <React.Fragment>
      <div className="top-img-vector">
      <img src={leftIllustrator} alt="..." />
    </div>
    <div className="social-btn">
            <div className="buttons">
              <div className="what space">
                <button className="btn btn-group" onClick={ () => contact('whatsapp')}>
                  <img src={what} />
                </button>
              </div>
              <div className="mail space">
                <button className="btn btn-group" onClick={ () => contact('mail')}>
                  <img src={mail} />
                </button>
              </div>
              <div className="call space">
                <button className="btn btn-group" onClick={ () => contact('call')}>
                  <img src={call} />
                </button>
              </div>
            </div>
          </div>
    </React.Fragment>
    
    
  );
};

const Navbar = ({ children }) => {
  return (
    <div className="container">
      <div className="header mt-4">{children}</div>
    </div>
  );
};

Navbar.Nav = Nav;
Navbar.Header = Header;
Navbar.HeaderContent = HeaderContent;

export default Navbar;
