import React from 'react'
import store from 'store'
import { useHistory } from "react-router-dom";

// -------------  import icons ------------- 

import Like from 'images/heart.png'
import Share from 'images/share.png'



export default function Card({ data }) {

    const history = useHistory();

    const priceCardDetails = () => {

        store.set('price-card-details',data)
        history.push('/market-place-details.html')
    }

    const subscribe = () => { 
        store.set('plan_data',data.minCapital,new Date().getTime() + (10*60*1000))
        history.push("/signup.html") //Redirect user to signup page ounce user select a plan
    }

    return (
        <div className="col-md-6 card-market-cards">
            <div className="card">
                <div className="row">
                    <div className="market-heading col-md-9">
                        <h4>{data.heading}</h4>
                    </div>
                    <div className="icon-market col-md-3">
                        <div className="market-icon">
                            <button className="btn btn-group">
                                <img src={Like} alt="..." />
                            </button>
                            <button className="btn btn-group">
                                <img src={Share} alt="..." />
                            </button>
                        </div>
                    </div>
                    <div className="col-12">
                        <p>{data.subheading}</p>
                    </div>
                    <div className="readmore-btn col-12">
                        <p className="p-anchor-tag" onClick={ () => priceCardDetails()}>Read more...</p>
                    </div>
                    <div className="tags col-12">
                        <div className="row">
                            {
                                data.tags.map((tag, idx) => {
                                    return (
                                        <div className="tag col" key={idx}>
                                            <span>{tag}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="percent-card col-md-12">
                        <div className="row">
                            <div className="col-md-3 right per-card">
                                <div className="percent-detail">
                                    <p>ROI</p>
                                    <span>{data.rOI}</span>
                                </div>
                            </div>
                            <div className="col-md-3 right per-card">
                                <div className="percent-detail">
                                    <p>Drawdown</p>
                                    <span><i className="fa fa-rupee-sign"></i>{data.drawdown}</span>
                                </div>
                            </div>
                            <div className="col-md-3 right per-card">
                                <div className="percent-detail">
                                    <p>Min. Capital</p>
                                    <span><i className="fa fa-rupee-sign"></i>{data.minCapital}</span>
                                </div>
                            </div>
                            <div className="col-md-3 per-card">
                                <div className="percent-detail">
                                    <p>Monthly Fee</p>
                                    <span><i className="fa fa-rupee-sign"></i>{data.monthlyFee}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mark-btn">
                                    <button onClick={() => subscribe() } className="btn btn-group table-btn">Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mark-btn">

                    </div>
                </div>
            </div>
        </div>
    )
}
