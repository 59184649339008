import How     from "images/how it works new2.png";
import Mobwork from "images/how it works new.png"

const WorkinSection = () => {
    return (
        <section>
            <div className="container">
                <div className="work wow slideInRight" data-wow-duration="1s" data-wow-delay="1s">
                    <div className="web-heading">
                        <h1>How It Works</h1>
                    </div>

                    <div className="works">
                        <div className="web-work">
                            <img src={How} alt="..." />
                        </div>
                        <div className="mob-work">
                            <img src={Mobwork} alt="..." />
                        </div>
                    </div>
                </div>
            </div>
        </section>
  );
};

export default WorkinSection;
