import React from 'react'
import contactIllustrator  from 'images/Contact Us.png'

export default function ContactMainCard() {
    return (
        <div className="col-md-6 contact-main">
            <div className="contact-content">
                <div className="web-heading-co">
                    <h1>Contact Us</h1>
                </div>
                <h3>Feel free to get in touch with us.</h3>
                <p>Share your query through below form. You'll hear back
                    from us within next 24 hours!!
                </p>
            </div>
            <div className="contact-down-img">
                <img src={contactIllustrator} alt="..." />
            </div>
        </div>
    )
}
