import React, { Component } from 'react'

// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'

// -------------  import background images ------------- 

import pageNotFoundImage   from '../images/404.png'

// -------------  import third party libraries --------------------

import WOW from 'wowjs';  /** @WOWJS for animation @version 1.1.3 **/

export default class PageNotFound extends Component {

    constructor(props){
        
        super(props)

        this.wow = new WOW.WOW({ live: false }).init();
        window.scroll({top: 0, behavior: "smooth"});
    }

    render() {
        return (
            <div className="wrapper">
                <section>
                    <Navbar>
                        <Navbar.Header></Navbar.Header>
                        <Navbar.Nav></Navbar.Nav>
                    </Navbar>
                </section>

                <section className="thank-bg">
                    <div className="404">
                        <div className="container">
                            <div className="content-404">
                                <img src={pageNotFoundImage} alt="..." />
                            </div>
                            <div className="btn-404">
                                <button className="btn btn-group zero">Back to home</button>
                            </div>
                        </div>
                    </div>
                </section>
               
                <section>
                    <Footer></Footer>
                </section>
            </div>
        )
    }
}
