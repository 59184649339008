import React, { Component } from 'react'
import store from 'store'
import CustomFormValidation from 'helpers/CustomFormValidation'
import Api from 'services/Api'

// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'

// -------------  import background images ------------- 

import signUpIllsutration from 'images/sign up illsutration.png'

// -------------  import page content --------------------

import SignUpForm   from 'components/signup/SignUpForm.jsx'


export default class SignUp extends Component {

    constructor(props){
        super(props)

        window.scroll({top: 0, behavior: "smooth"});

        if(store.get("plan_data") === undefined || store.get('plan_data') === '') {
            
            this.props.history.push('/price.html')
        }

        this.state = {
            loginError : '',
            formData  : {
                privacyPolicy : { value : '', error : false, alert:'' },
                fullname      : { value : '', error : false, alert:'' },
                useremail     : { value : '', error : false, alert:'' },
                number        : { value : '', error : false, alert:'' },
                username      : { value : '', error : false, alert:'' },
                password      : { value : '', error : false, alert:'' },
            }
        }

        this.setValidation = {
            rules :{ 
                privacyPolicy  : { require : true },
                fullname  : { require : true, minLength : 3, maxLength : 80   },
                useremail : { require : true, email  : true                   },
                number    : { require : true, number : true,phoneNumber: true },
                username  : { require : true, minLength : 3, maxLength  : 80  },
                password  : { require : true, minLength : 4, maxLength  : 14  },
            },
            messages : {
                privacyPolicy  : { require : 'Please select a privacy policy' },
                fullname  : { require : 'Please enter valid name', minLength : 'Please enter minimum 3 charector', maxLength:'please enter within 80 charector' },
                useremail : { require : 'Please enter valid email address', email : 'Not a valid email address' },
                number    : { 
                    require     : 'Please enter valid contact number', 
                    number      : 'Please enter valid contact number', 
                    phoneNumber : 'Please enter valid contact number'
                }, 
                username  : { require : 'Please enter valid username',      minLength : 'Please enter minimum 3 charector',maxLength:'please enter within 80 charector' },
                password  : { require : 'Please enter valid password',      minLength : 'Please enter minimum 4 charector',maxLength:'please enter within 14 charector' },
            }
        }


        this.validation = new CustomFormValidation(this.setValidation);
        this.api        = new Api()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange({ name, value}) {

        const response = this.validation.checkValidation(name,value)

        this.setState({ 
            formData:{ ...this.state.formData,
                [name] :{
                    value : value, 
                    error : response.error, 
                    alert : response.message
                }
            } 
        })
    }

    handleSubmit(event){
        event.preventDefault();

        const { error,state } =  this.validation.checkSubmitValidation(this.state)
        const data = {}

            data.email        = this.state.formData.useremail.value
            data.full_name    = this.state.formData.fullname.value
            data.password     = this.state.formData.password.value
            data.phone        = this.state.formData.number.value
            data.phone_number = this.state.formData.number.value
            data.plan_type    = store.get("plan_data") 
            data.username     = this.state.formData.username.value

        this.setState({ formData: state })

        if(error) { console.log('sorry form can not be submited'); return false; }

        this.api.signUp(data).then((response) => {

            const serverResponse = {
                data   : response.data,
                status : response.status
            }

                if(serverResponse.data.statusCode === "500" && serverResponse.data.message === "USERNAME_EXIST"){

                    this.setState({
                        formData:{
                            ...this.state.formData,
                            username:{ value : 'username', error : true, alert:'Username already exist'}
                        }
                    })
                }

                if(serverResponse.data.statusCode === "500" && serverResponse.data.message === "INVALID_USERNAME"){
                    this.setState({
                        formData:{
                            ...this.state.formData,
                            username:{ value : 'username', error : true, alert:'Username should be Alpha Numeric,without any special character & max 10 characters'}
                        }
                    })
                }

                if(serverResponse.data.statusCode === "500" && serverResponse.data.message === "EMAIL_ALREADY_EXISTED"){
                    this.setState({
                        formData:{
                            ...this.state.formData,
                            useremail:{ value : 'username', error : true, alert:'Email already exist'}
                        }
                    })
                }

                if(serverResponse.data.statusCode === "500" && serverResponse.data.message === "INVALID_EMAIL"){
                   this.setState({
                        formData:{
                            ...this.state.formData,
                            useremail:{ value : 'username', error : true, alert:'Email is not valid address'}
                        }
                    })
                 }


                if (serverResponse.data.statusCode === "200") {
                    
                    //removing selected plan store temp user info
                  
                    store.set("user_data",{
                        "username":data.username,
                        "email":data.email},
                        new Date().getTime() + (10*60*1000)
                    ) 
                    
                    if(store.get("plan_data") === "FREE_MONTHLY"){
                        store.clearAll()
                        this.props.history.push('/thankyou.html',{ redirectFrom: 'sign-up' })
                    }

                    else window.location = serverResponse.data.message
                }
            },
        ).catch(function (error) {
            this.setState({ loginError: "There is some technical issue please try again later." })
        });
        
    }
    

    render() {
        return (
            <div className="wrapper">
                <section>
                    <Navbar>
                        <Navbar.Header></Navbar.Header>
                        <Navbar.Nav></Navbar.Nav>
                    </Navbar>
                </section>

                <section className="thank-bg">
                    <div className="sign-up">
                        <div className="container">
                            <div className="row">
                                <SignUpForm
                                    handleSubmit = { this.handleSubmit    }
                                    handleChange = { this.handleChange    }
                                    formData     = { this.state.formData  } 
                                    error        = { this.validation.error}
                                    loginError   = { this.state.loginError}>
                                </SignUpForm>

                                <div className="col-md-7">
                                    <div className="signup-img">
                                        <img src={signUpIllsutration} alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <Footer></Footer>
                </section>
            </div>
        )
    }
}
