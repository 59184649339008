import React from 'react'

import Illustration from 'images/Back test strategy ill.png'

export default function BackTestStrategy() {
    return (
        <div className="row mt-2 mb-2 reverse-row">
            <div className="col-md-6 mt-auto mb-auto" >
                <div className="service-content">
                    <div className="service-heading">
                        <h1>Back Test Strategy</h1>
                    </div>
                    <div className="service-content">
                        <p>Backtest your strategy<br />
                            with our well curated tick data.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="service-img">
                    <img src={Illustration} alt="..." />
                </div>
            </div>
        </div>
    )
}
