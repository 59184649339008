
import graphLine from 'images/line graph.png'

import iconOne    from 'icons/home/features/icon1.png'
import iconTwo    from 'icons/home/features/icon2.png'
import iconThree  from 'icons/home/features/icon3.png'
import iconFour   from 'icons/home/features/icon4.png'
import iconFive   from 'icons/home/features/icon5.png'
import iconSix    from 'icons/home/features/icon6.png'


const cards = [
    {
        icon       : iconOne,
        heading    : 'Algo Trading Platform',
        subHeading : `We offer secure and reliable
        platform to
        automate your trading strategy.`
    },
    {
        icon       : iconTwo,
        heading    : 'Algo Strategy Guidence',
        subHeading : `Our premium service includes
        a detailed guidence to
        build your own trading strategy`
    },
    {
        icon       : iconFour,
        heading    : 'Backtest & Analysis',
        subHeading : `Backtest your strategy
        with our well cureted tick data`
    },
    {
        icon       : iconThree,
        heading    : 'Share & Subscribe',
        subHeading : `A Marketplace to get involve
        with each other buy sharing
        and subscribing strategies.`
    },
    {
        icon       : iconFive,
        heading    : 'Premium Strategies',
        subHeading : `Build your wealth
with our expert strategies.`
    },
    {
        icon       : iconSix,
        heading    : 'Expert Advice',
        subHeading : `Connect with us to get export
        advices about markets.
        Enhance your knowledge
        with our expert Analysts.`,
    }
]

const KeyFeaturesSection = () => {

    return (
        <section>
            <img src={graphLine} className="feature-bg bg-feature" alt="..." />
            <div className="container">
                <div className="features wow bounceInUp" data-wow-duration="2s" data-wow-delay="2s">
                    <div className="web-heading">
                        <h1>Our Key Features</h1>
                    </div>
                    
                    <div className="feature-card">
                        <div className="row">
                            {
                                cards.map((card,index) => {

                                    return (
                                        <div className="col-md-4 mt-4" key={index}>
                                            <div className="cards">
                                                <div className="card-body">
                                                    <div className="feature-card-icon">
                                                        <img src={card.icon} alt="..." />
                                                    </div>
                                                    <div className="card-text-heading">
                                                        <h5>{card.heading}</h5>
                                                    </div>
                                                    <p className="card-text">
                                                       { card.subHeading }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default KeyFeaturesSection;