import React from 'react'
import { Link } from 'react-router-dom'

import ourVisionIllustration from 'images/Our vision Illustration.png'

export default function OurVisionCard() {
    return (
        <div className="row">
            <div className="col-md-6">
                <div className="about-img">
                    <img src={ourVisionIllustration} alt="..." />
                </div>
            </div>
            <div className="col-md-6">
                <div className="web-heading-ab">
                    <h1>OUR VISION</h1>
                    <span>Share and build the next-gen of wisdom</span>
                    <p>We are aiming to make different market participant's trading
                        and investment experiences smoothen by leveraging cutting-
                        edge technologies along with our decades of experience.
                    </p>
                    <Link className="btn btn-group offer" to="/contact-us.html">Contact Us</Link>
                </div>
            </div>
        </div>
    )
}
