import React from 'react'
import Illustration from 'images/Share and subscribe illustration .png'

export default function ShareAndSubscribe() {
    return (
        <div className="row mt-2 mb-2">

            <div className="col-md-6">
                <div className="service-img1">
                    <img src={Illustration} alt="..." />
                </div>
            </div>
            <div className="col-md-6 mt-auto mb-auto" >
                <div className="service-content1">
                    <div className="service-heading">
                        <h1>Share & Subscribe</h1>
                    </div>
                    <div className="service-content">
                        <p>A Marketplace to get involve with each <br />
                            other buy sharing and subscribing strategies.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
