import { Link, NavLink } from 'react-router-dom';

import vector       from 'icons/home/marketPlace/vector.png'
import illustration from 'icons/home/marketPlace/illustration.png'
 
 //   this component is used in
 //      pages/home.js
 //      pages/marketplace.js 


const MarketPlaceSection = () => {
    
    return (
        <section>
            <div className="container">
                <div className="market mb-5 wow bounceInUp" data-wow-duration="1s" data-wow-delay="1s">
                    <div className="web-heading">
                        <h1>Marketplace</h1>
                    </div>
                    <div className="market-card">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="market-content">
                                    <h1>Subscribe to trading<br /> and investing algorithms,<br /> built by experts</h1>
                                    {/* <NavLink to="/market-place.html" className="learn-btn btn btn-group">Learn More</NavLink> */}
                                    <button className="learn-btn btn btn-group">Coming Soon</button>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="market-img">
                                    <img className="vector" src={vector} alt="..." />
                                    <img src={illustration} alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MarketPlaceSection;