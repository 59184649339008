import React, { Component } from 'react'

// -------------  import background images ------------- 

import lineGraphBackground   from 'images/line graph.png'

// -------------  import members images ------------- 

import nsFidai            from 'images/nsfidai.18c31709.jpg'
import rajeevJhunjhunwala from 'images/rajeev.617440ce.jpg'

// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'

// -------------  import page content --------------------

import AboutCard      from 'components/about/AboutCard.jsx'
import OurVisionCard  from 'components/about/OurVisionCard.jsx'
import MemberCard     from 'components/about/MemberCard.jsx'

// -------------  import third party libraries --------------------

import WOW from 'wowjs';  /** @WOWJS for animation @version 1.1.3 **/


export default class About extends Component {

    constructor(props){
        
        super(props)
        this.wow = new WOW.WOW({live: false}).init();

        this.members = [
            {
                img  : nsFidai,
                name :'NS Fidai',
                designation:'CO-FOUNDER',
                about:` Ex-Banker,Respected Name in
                       Indian Trading & Investing
                       Community with Over 35K followers
                       on Twitter, 30 years of market
                       experience of which last 16 years
                       as a practicing Technical Analyst,
                       Trainer & Mentorto more
                       than 1000+ students.`,
                socialMediaLinks:{
                    facebook: '/qodrr',
                    twitter : 'https://twitter.com/nsfidai',
                    linkedin: 'https://in.linkedin.com/in/nsfidai',
                }
                
            },

            {
                img:rajeevJhunjhunwala,
                name:'Rajeev Jhunjhunwala',
                designation:'CO-FOUNDER',
                about:`CFA & MBA by qualification. He is a
                       distinguished and prominent market
                       player. He brings on board more
                       than 3 decades of experience
                       as trader, investor & Broker.`,
                socialMediaLinks:{
                    facebook: '/qodrr',
                    twitter : '/qodrr',
                    linkedin: '/qodrr',
                }
            },
        ]

        window.scroll({top: 0, behavior: "smooth"});
    } 

    render() {
        return (
            <div className="wrapper">
                <section>
                    <Navbar>
                        <Navbar.Header></Navbar.Header>
                        <Navbar.Nav active='About'></Navbar.Nav>
                    </Navbar>
                </section>

                <section>
                    <div className="container">
                        <div className="about">
                            <AboutCard></AboutCard>
                            <OurVisionCard></OurVisionCard>
                        </div>
                    </div>
                </section>

                <section>
                    <img src={lineGraphBackground} className="features-bg bg-feature" alt="..." />
                        <div className="container">
                            <div className="team">
                                <div className="web-heading-ab-our">
                                    <h1>OUR TEAM</h1>
                                </div>
                                <div className="team-content">
                                    <span>Meet These Fine Folks.</span>
                                    <p>
                                        We have a team of market wizards, who come up with rich experience and well know name<br />
                                        in Indian Trading & Investing CommunityWe have a mix blend of Experience and technology in
                                        our team.
                                    </p>
                                </div>
                            
                                <div className="team-card">
                                    <div className="row center-content">
                                        {
                                            this.members.map((member,index) => <MemberCard key={index} card={member}></MemberCard>  )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>

                <section>
                    <Footer></Footer>
                </section>
            </div>
        )
    }
}
