
import { Link } from 'react-router-dom'

const SignUpContent = () => {
    return (
        <>
            <div className="web-heading">
                <h1>Sign-up successful !!</h1>
            </div>
            <div className="thank-content">
                <p>Thank you for Signing-up with us.Your Account has been created and details has been mailed to you.</p>
                <p>Click here to quickly sign-in to your account? <a href="https://app.qodrr.com">Sign</a> In Or <Link to="contact-us">Contact</Link> our Support team for any assistance.</p>
            </div>
        </>
    )
}

const MemberShipError = () => {
    return (
        <>
            <div className="web-heading">
                <h1>Sign-up successful !!</h1>
            </div>
            <div className="thank-content">
                <p>Thank you for Signing-up with us.Your Account has been created and details has been mailed to you.</p>
                <p>Your payment has been processed successfully but due to some technical error,
                     your membership is not yet active.Our support team will contact you in next 24 hours</p>
            </div>
        </>
    )
}

const MemberSuccess = ({ paymentId,paymentStatus }) => {

    return (

        <div className="thank-response" style={{ color:'white', textAlign:'center' }}>
            <div className="web-heading">
                <h1>Payment Response</h1>
            </div>
            <div>
                Thank you for Signing-up with us.Your payment request have been processed successfully.
                <h3>Pamyent ID : {paymentId}</h3>
                <h3>Pamyent Status : { paymentStatus }</h3>
            </div>
            <div>
                Click here to quickly sign-in to your account?{" "}
                <a href="https://app.qodrr.com/" >Sign In</a>&nbsp; Or{" "}
                <Link to="/contact-us.html">Contact</Link>{" "}our Support team for any assistance.
            </div>
        </div>
    )
}

const ContactUsContent = () => {
    return (
        <>
            <div className="web-heading">
                <h1>Thank you for getting in touch! </h1>
            </div>
            <div className="thank-content">
                <p>We appreciate you contacting us/ Qodrr. One of our colleagues will get back in touch with you soon! Have a great day!</p>
            </div>
        </>
    )
}


const emptyDiv = () => {
    return <></>
}


const Content = {}

Content.SignUp          = SignUpContent
Content.ContactUs       = ContactUsContent
Content.emptyDiv        = emptyDiv
Content.MemberShipError = MemberShipError
Content.MemberSuccess   = MemberSuccess

export default Content;