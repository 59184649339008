import React, { Component } from 'react'

// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'

// -------------  import page content ------------- 

import Accordion from 'components/accordiaon/Accordion.jsx'

// -------------  import third party libraries --------------------

import WOW from 'wowjs';  /** @WOWJS for animation @version 1.1.3 **/

// ------------- import static data -------------

import frequentlyAskedQuestions from "helpers/frequentlyAskedQuestions.js"


export default class Faq extends Component {

    constructor(){
        super()

        this.defaultQuestions =  frequentlyAskedQuestions
        this.state = {
            search:'',            
            questions:this.defaultQuestions
        }
        
        this.wow = new WOW.WOW({ live: false }).init();

        this.handleSubmit = this.search.bind(this);
        window.scroll({top: 0, behavior: "smooth"});
    }

    search(event){

        const keyword = event.target.value

        if(keyword.length > 2){

            const response = this.defaultQuestions.filter((data) => data.question.includes(keyword))
            this.setState({ questions: response })
        }

        else if (keyword.length === 0)  this.setState({ questions:  this.defaultQuestions })
       
    }

    render() {
        return (
            <div className="wrapper">
                <section>
                    <Navbar>
                        <Navbar.Header></Navbar.Header>
                        <Navbar.Nav></Navbar.Nav>
                    </Navbar>
                </section>

                <section>
                    <div className="faq">
                        <div className="container">
                            <div className="web-heading-faq">
                                <h1>FAQs</h1>
                            </div>
                            <div className="content-faq">
                                <h3>You have Questions ?</h3>
                                <p>Here are some frequently asked questions about our hotels from our loving customers.
                                    Should you have any other questions, feel free to reach out via the contact form below.</p>
                            </div>

                            <div className="search">
                                <input type="text"  className="searchTerm" onKeyUp={(event) => this.search(event) }  placeholder="Search..."/>
                                <button type="submit" className="searchButton">
                                    <i className="fa fa-search"></i>
                                </button>
                             </div>

                            <div className="acc-content">
                                {
                                    this.state.questions.map((data,index) => {
                                        return <Accordion data={data} key={index} index={index}></Accordion>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <Footer></Footer>
                </section>
            </div>
        )
    }
}
