import React from "react";

export default function TermsAndCondition() {
  return (
    <div className="container">
      <div
        className="web-heading-ab wow slideInRight"
        data-wow-duration="1s"
        data-wow-delay="1s"
      >
        <div className="term-content">
          <div className="term-web-heading">
            <h1>Terms And Condition</h1>
          </div>
          <span>Last updated: April 1st, 2021</span>
          <p>
            PLEASE CAREFULLY READ THESE TERMS OF USE BEFORE ACCESSING AND USING
            [QODRR.COM] AND / OR FEATURES, PROGRAM(S) OR SERVICES OFFERED
            THEREIN (COLLECTIVELY THE “PLATFORM”).
          </p>
          <p>
            YOU ACCESSING AND USING THE PLATFORM ASSUMES THAT YOU HAVE READ AND
            FULLY UNDERSTOOD (INCLUDING WITH THE HELP OF YOUR FINANCIAL, LEGAL,
            TAX OR OTHER ADVISORS) THESE TERMS OF USE AND HAVE PROVIDED YOUR
            UNCONDITIONAL ACCEPTANCE TO EACH OF THESE TERMS OF USE.
          </p>
          <p>
            ACCEPTANCE OF THESE TERMS OF USE AS ABOVE CONSTITUTE AN IMPORTANT
            PRE-CONDITION FOR YOU TO ACCESS AND USE THE PLATFORM. IF YOU DO NOT
            ACCEPT OR NOT WISH TO BE BOUND BY THESE TERMS OF USE OR IF YOU
            CANNOT REMAIN IN COMPLIANCE WITH THESE TERMS OF USE, YOU SHOULD NOT
            ACCESS AND USE THE PLATFORM.
          </p>
          <div className="term-content">
            <ul>
              <li>
                These Terms of Use constitute a legally binding agreement
                between [PITTER SHARE BROKING PRIVATE LIMITED] provider of the
                Platform (hereinafter referred to as "us" or "we" or “our”) and
                you (“you” or “user” or “users”), the individual logging in to
                the Platform, and sets forth the terms and conditions that
                govern your access and use of the Platform. These Terms of Use
                assume that you have full power and authority to enter into this
                agreement and to carry out all of your obligations hereunder.
              </li>
              <li>
                We may update or amend these Terms of Use from time to time by
                posting such updates or amendments to the Platform. Your access
                and use of the Platform after we post any such updates or
                amendments will constitute your agreement to those updates or
                amendments as well.
              </li>
              <li>
                Unless otherwise specified, we grant to you a non-exclusive,
                non-transferable, limited right and license (subject to these
                Terms of Use) to access the Platform as per the subscription
                made by you. Certain features of the Platform are available only
                to users who sign-up/register with us and pay subscription fee
                depending on the plans available.
              </li>
              <li>
                To become a registered user, you will need to sign up by
                providing your current, complete and accurate personal
                identifiable information. We are eligible to know inter alia
                (without limitation) your real name, address, details of
                identification and address proofs, the email address and
                telephone number through which we can correspond with you, as
                prompted by the applicable registration form. You further agree
                to keep any registration information you provide to us current,
                complete and accurate. Once signed up / registered, you may
                access and use the Platform by signing in using your username,
                password, any other authentication credential as may be required
                at the Platform. You shall be responsible to change your
                password and other authentication credentials from time to time
                as per our prevailing policies. The safe keeping of your user
                name, password or other credential for log-in shall be your
                responsibility alone and we shall in no way be held responsible
                for any unauthorized access to your account or for any
                consequences (including loss) occurring to you due to the same.
              </li>
              <li>
                To use certain features of the Platform, users will need to have
                functional online trading and demat account with one of the
                stock brokers listed on the Platform. We do not recommend,
                propagate or have any preference in terms of the stock broker
                you choose. The stock brokers listed on the Platform are the
                ones that afford online trading using system-run trading
                strategies. You are free to choose any one of them to be your
                preferred stock broker.
              </li>
              <li>
                To use certain features of the Platform, you will also be
                required to set up connection with the stock brokers’
                Application Programming Interface (“API”) that permits you to
                trade online using system-run trading program(s) available on
                the Platform. Once connection to the API is duly set up, you
                shall be required to log-in to your stock brokers’ online
                account and activate the API daily (or at such shorter frequency
                as may be required by your stock broker) by verifying your
                username, password or other credentials as per your stock
                broker’s policies. This log-in shall be through your stock
                brokers’ user interface and we shall be in no way be privy to or
                be able to access or store any of the credentials used by you to
                log-in to your stock brokers’ account. The safe keeping of your
                user name, password, tickets, codes, keys or other credentials
                for log-in to your stock brokers’ account or to set up API shall
                be your responsibility alone and we shall in no way be held
                responsible for ensuring connectivity or for any unauthorized
                access to your stock broker’s account or for any consequences
                (including loss) occurring to you due to the same. Further, we
                shall not in any way be responsible for any failure, delay,
                malfunctions or other issues in you being able to log-in to
                stock brokers’ account or being able to use your stock broker’s
                services properly. You shall be liable to resolve all such
                issues directly with your stock broker.
              </li>
              <li>
                The access and use of the Platform are for your personal use
                only subject to your unconditional acceptance to these Terms of
                Use and continued compliance by you of the provisions of these
                Terms of Use. Access and use of the Platform assumes such access
                and use being carried out by you for your own personal account.
                In case the account belongs to a non-individual, the access and
                use of the Platform assumes that the individual user accessing
                and using the Platform at all times has: (i) adequate
                authorization to act and take decisions on behalf of the
                non-individual account holder; and (ii) to simultaneously bind
                the non-individual with all of these Terms of Use and with all
                the actions and decisions of individual user taken while
                accessing and using the Platform on behalf of such
                non-individual. We are in no way responsible for verifying the
                credentials or authority of the person accessing or using an
                account. Any dispute relating to access of unauthorized use of
                such non-individual’s account shall be between the individual
                user (you) and such non-individual account holder. We shall in
                no way be held responsible for any unauthorized access to any
                account or for any consequences (including loss) occurring to
                you, such non-individual account holder or to any third party
                due to the same. YOU SHALL NOT PERMIT ANY OTHER PERSON TO ACCESS
                OR USE THE PLAFORM ON YOUR BEHALF. YOU SHALL AT ALL TIMES REMAIN
                SOLELY AND ENTIRELY RESPONSIBLE FOR ANY AND ALL ACTIVITIES AND
                CONDUCT (AND BE LEGALLY BOUND BY THEM), WHETHER BY YOU OR ANYONE
                ELSE (WITH OR WITHOUT YOUR PERMISSION) THAT ARE CONDUCTED
                THROUGH YOUR ACCOUNT.
              </li>
              <li>
                The Platform is a web-based ‘DO-IT-YOURSELF’ platform on which a
                user can inter-alia create his / her own system-run trading
                strategies using the available technical analysis parameters,
                customize available pre-coded system-run trading strategies
                samples made using technical analysis parameters, or require us
                to work with him / her in assisting in coding system-run trading
                strategies samples using technical analysis parameters as per
                his/her instructions (any of the abovementioned system-run
                trading strategies is hereafter referred as ‘program’ or
                ‘program(s)’). The users should note that program(s) made
                available through the Platform are mere samples. In each case
                the program(s) will require user customization, back-testing and
                exercise of discretion to deploy it/them in the live markets or
                not. WE DO NOT MAKE ANY REPRESENTATION OR WARRANTY OF THE
                PLATFORM OR ANY OF ITS FEATURES (INCLUDING PROGRAM(S)) BEING
                PRODUCT OF / BY EXPERTS OR PROFESSIONALS OR THE PLATFORM OR ANY
                OF ITS FEATURES (INCLUDING PROGRAM(S)) BEING FIT FOR THE USER OR
                FOR DEPLOYMENT IN THE LIVE MARKETS. THE AVAILABLE PROGRAM(S) ARE
                JUST SAMPLES AND THEREFORE USERS ARE EXPECTED TO CUSTOMISE AND
                TEST PROGRAM(S) BY THEMSELVES, SATISFY THEMSELVES OF THE
                PROGRAM(S)’ FITNESS FOR THEIR USE AND DEPLOY THEM FOR TRADING IN
                THE LIVE MARKETS AT THEIR OWN SOLE RISK.
              </li>
              <li>
                The use and deployment of these program(s) is absolutely
                discretionary and a decision to use and deploy them is
                absolutely in users’ hand. The program(s) use and their
                deployment (if any) are strictly meant for professional traders.
                By agreeing to these Terms of Use you represent to us that you
                are fully conversant with and have experience firsthand in the
                working of the stock markets, index/stock trading and the
                futures and options trading. By agreeing to these Terms of Use
                you represent to us that you fully understand the risks
                associated with any of the foregoing. IF YOU DO NOT POSSESS
                RELEVANT EXPERIENCE AND KNOWLEDGE, YOU SHOULD NOT ACCESS AND USE
                THE PLATFORM. THE PLATFORM OR ANY OF ITS FEATURES (INCLUDING
                PROGRAM(S)) ARE MEANT FOR USE OF PROFESSIONAL TRADERS ONLY.
              </li>
              <li>
                The Platform provides facility for you to back-test these
                program(s) and derive the efficiency or efficacy of such program
                for your use, independently. Back-testing is the process of
                testing a trading strategy on relevant historical data to ensure
                its viability before the user risks any actual capital. A user
                can simulate the trading of a strategy over an appropriate
                period of time and analyze the results for the levels of
                profitability and risks.
              </li>
              <p>
                WE DO NOT IN ANY WAY REPRESENT, WARRANTY OR GUARANTEE ANY
                EFFICIENCY, EFFICACY OR PERFORMANCE (INCLUDING AS TO RESULTS
                THAT PROGRAM(S)’ DEPLOYMENT MAY YIELD) OF THE PROGRAM(S) OR ITS
                SUITABILITY TO YOU. WE DO NOT IN ANY WAY REPRESENT, WARRANTY OR
                GUARANTEE THAT DEPLOYMENT OF THE PROGRAM(S) WILL IN ANY WAY
                YIELD PROFITS OR POSITIVE FINANCIAL RETURNS TO YOU.
              </p>
              <li>
                You can visit the information provided on the Platform to fully
                understand the working of the program(s) and the directions and
                process to use them. For proper functioning of the program(s),
                it is necessary that you satisfy all the pre-condition(s) for
                program(s)’ use (such as system requirements, capital
                requirements, lot sizes etc.) as may be prescribed and follow
                the directions and processes fully and accurately. Use of the
                program(s) will require your discretion at several places
                including in the form of customising the program(s) by setting
                certain parameters as per your need, for example, as to funds to
                be utilized, order size, the product on which the program(s)
                will work on, stop losses, time-frames (5 minutes, 15 minutes,
                hourly or daily), order type (MIS or Normal) and other money
                management and risk management tools, back-testing the program,
                satisfying yourself of its fitness for your use and for
                deployment of the program(s) in the live markets. To be clear,
                by exercising your discretion as aforementioned, you are
                creating your own custom-made system-run trading strategy(ies)
                for the result of which you shall be absolutely and solely
                responsible.
              </li>
              <p>
                YOU ARE ON YOUR OWN ACCORD RESPONSIBLE FOR TESTING, SETTING
                PARAMETERS, SATISFYING YOURSELF OF THE PERFORMANCE OF THE
                PROGRAM(S), DECIDING TO DEPLOY ANY OF THE PROGRAM(S) IN THE LIVE
                MARKETS AND FOR ANY PROFITS / LOSSES GENERATED OUT OF IT.
              </p>
              <li>
                You fully understand and acknowledge that back-testing results
                are hypothetical and are simulated on historical data. The
                performance results have certain inherent limitations. Unlike
                the results shown in an actual performance record, these results
                do not represent actual trading. These trades have not actually
                been executed, these results may have under-or over-compensated
                for the impact especially when we have lack of liquidity in the
                market or news driven events or macro/micro economic factors or
                any other conditions. Simulated or hypothetical trading
                strategies in general are also subject to the fact that they are
                designed with the benefit of hindsight.
                <p>
                  NO REPRESENTATION OR WARRANTY IS BEING MADE BY US ON THE
                  ACCURACY OF THE BACK-TESTING RESULTS OR ON THE FACT THAT ANY
                  ACCOUNT WILL OR IS LIKELY TO ACHIEVE PROFITS OR LOSSES SIMILAR
                  TO THOSE BACK-TESTED.
                </p>
                In addition, hypothetical trading does not involve financial
                risk, and no hypothetical trading record can completely account
                for the impact of financial risk in actual trading. For example,
                the ability to withstand losses or to adhere to a particular
                trading strategy(ies) in spite of trading losses are material
                points which can also adversely affect actual trading results.
                There are numerous other factors related to the markets in
                general or to the implementation of any specific strategy(ies)
                trading which cannot be fully accounted for in the preparation
                of hypothetical performance results and all of which can
                adversely affect actual trading results.
                <br />
                Chart data is subjected to minor variations from market time to
                post market times due to standard data adjustments.
              </li>
              <li>
                The subscription to the Platform can at any time be terminated
                by you at your discretion.
              </li>
              <li>
                In case you decide to deploy a program in the live markets, the
                Platform facilitates termination of such deployment by you
                anytime during the live market. If a program is stopped by the
                user or it terminates for any other reason (including but not
                limited to user not meeting margin requirements etc.) and it has
                not generated any position in user’s account, it will directly
                stop otherwise the user will be presented with an option to
                either stop the program by keeping the positions open or to exit
                positions at market and stop the program. Once you effectively
                terminate the deployment of the program(s), it will shut down
                its processing subject to the system and other procedural
                requirements (properly shutting down deployment of the
                program(s) requires to follow certain processes that may cause
                delays). Once program(s) is shut down, you shall be able to
                review and reconfirm your pending trade positions (if any) as
                may have been left open by the program(s) at your stock broker’s
                user interface. It shall be your sole responsibility to
                independently manage/close such open positions at your sole
                discretion.
                <br />
                User shall have to log-in to the Platform; log-in, activate and
                properly sync its stock broker’s API and user’s account; and
                deploy a program on a daily basis following the systems,
                directions and processes prescribed at the Platform or by the
                stock broker.
                <br />
                You understand that once a program(s) is deployed in the live
                market, it requires your active involvement in reviewing the
                trades being carried out. You understand that volatility is the
                nature of the market and hence trade positioning requires user’s
                constant supervision. You take full responsibility for trades
                executed through any of the program(s) and are fully aware of
                and unconditionally own all the consequences that such trades
                may derive. The trade position created or squared off by the
                program(s) shall all be visible on your stock broker’s user
                interface. In case the Platform also provide an account of such
                positions, you MUST always rely on the information made
                available to you by your stock broker.
              </li>
              <li>
                You shall be solely responsible to ensure that you meet your
                broker’s / stock exchange’s requirement fully at all times to
                ensure execution of the orders generated by a program. We do not
                verify the margin or other requirements necessary for successful
                placement of order(s), your broker / exchange determines them. A
                program may fail to generate results or order(s) may get
                rejected due to reasons such as insufficient capital, etc. for
                which you shall be solely responsible. If the user's order is
                rejected due to various reasons such as shortage of funds,
                circuit limit hit, no liquidity in market, etc., the deployment
                of the program will be terminated as if the user has actively
                terminated the program during the live-market. This is done in
                order to avoid unnecessary tracking of instruments where the
                order placement failed. However, the user can deploy the program
                again.
              </li>
              <li>
                You shall be solely responsible to ensure that you and your
                stock broker at all times comply with all the legal and
                regulatory requirements in relation to access and use of the
                Platform, deployment of a program(s) live market and / or trades
                generated by it in the user’s account.
              </li>
              <li>
                You agree and understand that functions of the Platform are
                dependent on many technical and non-technical factors such as
                the internet connections, network conditions, location, time of
                the day, server load, data availability etc. at ours / user’s /
                data provider’s / stock broker’s / stock exchanges etc. that are
                beyond our control. We recommend users to be logged in to the
                Platform, keep it open in their browser and maintain a fast
                uninterrupted internet connection to their devices to see the
                best performance. We use cloud services for functioning of the
                Platform and its features (including the program(s)) and we rely
                on third party services for charting data, charts, technical
                signals and market data including but not limited to for ticks,
                OHLCV etc. If these services are down or there are other
                technical / non-technical issues, we may not be able to deliver
                performance at the Platform. We shall also not be liable in any
                way for any inaccuracy of the data, charts, technical signals
                received from such third party(ies) or for the results generated
                on the Platform using such data. Similarly, execution of live
                market trades is the responsibility of your stock broker. If
                your stock broker’s services are down or there are other
                technical / non-technical issues at the stock broker’s end, we
                may not be able to deliver performance at the Platform.
                <p>
                  YOU UNDERSTAND ALL THESE LIMITATIONS AND CONFIRM THAT WE SHALL
                  IN NO WAY BE LIABLE FOR ANY FAILURES, ERRORS, LAG, LATENCY,
                  DELAYS, DISRUPTIONS OR POOR / NON-PERFORMANCE OF THE PLATFORM
                  OR ANY OF ITS FEATURES (INCLUDING THE PROGRAM(S)) ON ANY OF
                  THESE COUNTS OR FOR ANY OTHER TECHNICAL OR NON-TECHNICAL
                  REASONS WHATSOEVER OR FOR ANY LOSS (OF ANY NATURE WHATSOEVER)
                  THAT YOU MAY INCUR DUE TO ANY OF THE AFOREMENTIONED.
                </p>
              </li>
              <li>
                You acknowledge and understand that Platform or any of the
                features available therein are not intended to supply
                investment, financial, tax or legal advice in any way
                whatsoever. We, the Platform or any of the features available
                therein (including the program(s)) do not solicit any
                investment, do not make any recommendation relating to any
                investment, do not solicit use of the Platform for any
                investment, and do not render any investment advice. Any
                observations concerning any security, trading strategy or
                investment strategy provided at the Platform should not be
                considered a recommendation to buy, sell or hold such investment
                or security or to make any other investment decisions.
              </li>
              <li>
                We offer no advice regarding the nature, potential value, risk
                or suitability of any particular investment strategy, trading
                strategy, transaction, security or investment. You acknowledge
                and agree that any use of the Platform, any decisions made in
                reliance on the Platform, including any trading or investment
                decisions or strategies, are made at your own risk. If
                investment, trading or other professional advice is required,
                the services of a competent, licensed professional should be
                sought. None of our employees, agents or representatives is
                authorized to provide any such advice.
              </li>
              <li>
                You are solely responsible for input, correctness, and accuracy
                of the information required on the Platform. The quality of a
                product's analysis and optimization depends on the user's
                inputs. After deployment you, as user of the system, are solely
                responsible for ensuring the quality of all its inputs. As such,
                you must carefully review all input parameters in all ways
                necessary to ensure their accuracy and fidelity. While there are
                other factors governing analysis and optimization accuracy, the
                quality of the product outputs depends on the accuracy of your
                inputs. The trades generated by a program may increase beyond
                what is practical to execute, due to broker execution limits and
                the difficulties in executing a complex trade in an all-or-none
                fashion. Moreover, once the trade is executed, the management of
                a complex trade becomes more difficult than is normally the
                case. Another factor is that you may not be authorized to
                execute all contract types found in the solutions generated. We
                make no representation that a solution generated by the Platform
                can be executed and effectively monitored and managed in
                practice. It is entirely your responsibility to assess the
                appropriateness, suitability, and practicality of the solutions
                generated. It is also your responsibility to ensure the trade is
                executable and manageable, and appropriate for your needs.
              </li>
              <li>
                All intra-day program(s) set ups shall place order type MIS and
                shall be stopped at 3:00 PM. Open MIS positions, if any, will
                have to be squared off by your stock broker before market close
                as per its policy. We shall in no way be responsible for closure
                of such orders. Further, the orders (including stop loss orders,
                if any) initiated by the program(s) shall be ‘market orders’ and
                we shall not be responsible in any way for higher slippages
                caused due to this. For positional program(s) set up, they shall
                place CARRY FORWARD / DELIVERY order and shall run until
                terminated. Unless terminated early, they shall until the market
                close on a trading day, however, on the next trading day, to
                ensure continuity, the user shall, before the market opening,
                have to redeploy the program(s) after having properly synced his
                / her open positions (if any) of the previous day to the
                program(s) by following the systems, directions and processes as
                prescribed.
              </li>
              <li>
                You acknowledge and understand that: (a) we can only give you
                the benefits of accessing the Platform and using its features by
                conducting business through the Internet, and therefore we need
                you to consent to our giving you Communications (defined below)
                electronically or over phone using SMSs or other internet based
                messaging application(s) such as WhatsApp, Telegram, Signal
                etc., and (b) this Section informs you of your rights when
                receiving Communications from us electronically or over phone.
                For contractual purposes, you: (i) consent to receive
                communications from us in an electronic form or over phone /
                internet messaging, and (ii) agree that all terms and
                conditions, agreements, notices, documents, disclosures, and
                other communications (“Communications”) that we provide to you
                electronically or over phone / internet messaging satisfy any
                legal requirement that such Communications would satisfy if they
                were in writing. Your consent to receive Communications and do
                business electronically or over phone / internet messaging, and
                our agreement to do so, applies to all of your interactions and
                transactions with us. The foregoing does not affect your
                non-waivable rights. You further agree to receive all service /
                promotional communications from us in electronic form or over
                phone / internet messaging.
                <p>
                  To ensure robust communications between us, you should provide
                  us with your active mobile number (that has the SMSs and
                  internet messaging application as prescribed at the Platform
                  from time to time) and email addresses and to keep such
                  information current, complete and accurate at all times.
                </p>
              </li>
              <li>
                You are aware and acknowledge that trading over the Internet
                involves many uncertain factors and complex hardware, software,
                systems, communication lines, peripherals, etc., which are
                susceptible to interruptions and dislocations; and the Platform
                or any of the features therein (including the program(s)) may at
                any time be unavailable without further notice. We do not make
                any representation or warranty that the Platform or any of the
                features therein (including the program(s)) will be available to
                you at all times without any interruption. You agree that you
                shall not have any claim against us on account of any
                suspension, interruption, non-availability or malfunctioning of
                the Platform or any of the features therein (including the
                program(s)) that are meant to run on the internet, for any
                reason whatsoever.
              </li>
              <li>
                <h5>
                  ANY FEES OR CHARGES ONCE PAID WILL BE STRICTLY NON-REFUNDABLE.
                </h5>{" "}
                Unless specified otherwise, all prices shall be subject to
                applicable taxes that shall be solely on the account of the
                user. The trades shall be carried directly in the user’s stock
                broker’s account and hence any capital gains tax or other
                liability (including any of user’s stock broker’s claims,
                trading fee, taxes or other charges) shall be solely user’s
                responsibility. The billing cycles shall be as prescribed at the
                Platform. We shall consider one month as 30 days, three months
                as 90 days, six months as 180 days or one year as 365 days from
                the first day of the billing cycle. The subscription might or
                might not be auto-renewing subscription depending on the
                platform or the payment method you might be subscribing from, so
                you should fully understand and are responsible for reading the
                details of the plan and its setting(s) at the time of
                subscription. We bill on the first day of the billing cycle as
                soon as you subscribe. In case you choose auto renewing
                subscription, the billing cycle will get renewed for another
                cycle of the same period as the previous cycle automatically at
                the end of the previous cycle. In order to avoid getting charged
                for the next billing cycle, you need to actively cancel at least
                one day before the commencement of the next billing cycle. You
                shall be free to cancel your subscription anytime. Once you
                cancel your subscription, you will no longer be able to use the
                Platform, excluding for the current billing cycle that you have
                already paid for. Notwithstanding anything contained herein, we
                reserve the right to cancel any user (your) subscription anytime
                with or without prior notice to the user (you).
              </li>
              <li>
                The Platform, website [https://qodrr.com], its general
                structure, its features, as well as text, animated or
                non-animated images, know-how, drawings, graphics, as well as
                the look and feel and the features shall at all time remain our
                exclusive property. You are only entitled to view information
                for your private use only. You may not reproduce, by any means,
                or process, in whole or in part, distribute, publish, transmit,
                create derivative works based on, modify or sell any such
                materials contained on the Platform. Any total or partial
                representation of the Platform or the features available therein
                by any process without our express authorization is prohibited
                and illegal and will be subject to remedy and action by us
                against you as may be provided under applicable laws.
              </li>
              <li>
                The Platform may provide technical infrastructure to permit
                running on/through it of certain features (including the
                program(s)) that are coded / developed by you or independent
                third-parties; or that are available on any other portal,
                application or means. In such an event, you shall be running
                such features (including the program(s)) at your own sole risk
                and we shall in no way be liable for the efficiency, efficacy or
                performance (including as to results that their deployment may
                yield) of such features (including the program(s)) or their
                suitability to you. Further, in case of the above and in case
                that you are accessing the Platform or any of its features
                (including the program(s)) from/through/via any other portal,
                application or means; or that you are accessing any other
                portal, application or means from/through/via the Platform or
                any of its features (including the program(s)), we shall at all
                times continue to be protected by these Terms of Use and these
                Terms of Use shall mutatis mutandis apply on you.
                <p>
                  WE CONDUCT NO INVESTIGATION OR REVIEW OF ANY OF
                  YOURS/THIRD-PARTY’S FEATURES (INCLUDING THE PROGRAM(S)) OR OF
                  ANY OF THE OTHER PORTALS, APPLICATIONS OR MEANS AS REFERRED TO
                  ABOVE OR OF THE SERVICES, FEATURES OR TRADE SIGNALS ETC.
                  PROVIDED BY THEM, AND DO NOT RECOMMEND, ENDORSE, APPROVE OR
                  DISAPPROVE OF ANY OF THEM. THEIR USE OR DEPLOYMENT SHALL BE AT
                  YOUR OWN SOLE RISK AND WE SHALL IN NO WAY BE LIABLE FOR THEIR
                  EFFICIENCY, EFFICACY OR PERFORMANCE OR THEIR SUITABILITY TO
                  YOU.
                </p>
              </li>
              <li>
                You agree to indemnify, defend, and hold us, and our
                subsidiaries, parent, affiliates, officers, directors, agents,
                co-branders, sponsors, distributors, or other partners,
                employees, contractors, sponsors and representatives harmless
                from and against any and all claims, demands, actions, causes of
                action, damages, losses, costs or expenses (including reasonable
                attorneys' fees and disbursements) which arise or relate,
                directly or indirectly, out of, from or to: (i) your breach of
                these Terms of Use, (ii) violation or non-compliance of any
                applicable laws or regulations by you or your stock broker,
                including but not limited to regulations and guidelines
                governing trading via system-run trading strategies, (iii) any
                allegation that any materials that you submit to us infringe,
                misappropriate, or otherwise violate the copyright, trade
                secret, trademark or other intellectual property rights, or any
                other rights of a third party, or (iv) access or use of the
                Platform and/or its feature by you or anyone using your account,
                with or without your consent. This Section shall survive in the
                event this Agreement is terminated for any reason.
              </li>
              <li>
                You acknowledge and agree that we are only willing to provide
                access to the Platform and to provide our services if you agree
                to certain limitations of our liability to you and to third
                parties. Neither us nor our subsidiaries, parent, affiliates,
                officers, directors, agents, co-branders, sponsors,
                distributors, or other partners, employees, contractors,
                sponsors or representative: make any representation or warranty
                of any sort; or offer any guarantee or indemnity; or are
                responsible or liable to you or anyone else for any loss or
                injury or any indirect, incidental, consequential, special,
                exemplary, punitive or other damages under any contract,
                negligence, strict liability or other theory arising out of or
                relating in any way to: (i) the use of, delays in operation,
                transmission or response of, or inability to use the Platform or
                its features (including the program(s)) and other services; (ii)
                any content contained on the Platform or its features (including
                the program(s)) and other services; (iii) statements or conduct
                posted or made publicly available on the Platform or its
                features (including the program(s)) and other services; (iv) any
                product or service purchased or obtained through the Platform or
                its features (including the program(s)) and other services; (v)
                any action taken in response to or as a result of any
                information available / results generated on the Platform or its
                features (including the program(s)) and other services; (vi) any
                damage caused by mistakes, inaccuracies, omissions, errors,
                interruptions or loss of access to, deletion of, failure to
                store, failure to back up, or alteration of any content on the
                Platform or its features (including the program(s)) and other
                services, or (vii) any other failure of performance of the
                Platform or its features (including the program(s)) and other
                services or other matter relating to the Platform or its
                features (including the program(s)) and other services, in each
                case whether or not caused by events beyond the control of us,
                our subsidiaries, parent, affiliates, officers, directors,
                agents, co-branders, sponsors, distributors, or other partners,
                employees, contractors, sponsors or representative.
                <p>
                  IN NO EVENT SHALL OUR OR OUR SUBSIDIARIES, PARENT, AFFILIATES,
                  OFFICERS, DIRECTORS, AGENTS, CO-BRANDERS, SPONSORS,
                  DISTRIBUTORS, OR OTHER PARTNERS, EMPLOYEES, CONTRACTORS,
                  SPONSORS OR REPRESENTATIVE’S TOTAL LIABILITY TO YOU OR TO ANY
                  THIRD PARTY FOR ANY AND ALL DAMAGES, LOSSES, AND CAUSES OF
                  ACTION (WHETHER IN CONTRACT, TORT, STATUTORY, OR OTHERWISE)
                  EXCEED RE. 1 (INDIAN RUPEE ONE ONLY). WE OWE NO RESPONSIBILITY
                  OR LIABILITY WHATSOEVER TO THE USERS OF FEATURES OR SERVICES
                  PROVIDED AT THE PLATFORM FOR FREE (WITHOUT ANY CHARGE OR FEE
                  PAYMENT).
                </p>
                Some jurisdictions do not allow the exclusion of certain
                warranties or the limitation or exclusion of certain types of
                liability. Accordingly, some of the above limitations and
                disclaimers may not apply to you. To the extent that we may not,
                as a matter of applicable law, disclaim any implied warranty or
                limit liabilities, the scope and duration of such warranty and
                the extent of our liability will be the minimum permitted under
                such applicable law.
              </li>
              <li>
                Force Majeure: We shall not be liable hereunder by reason of any
                failure or delay in our performance of our obligations on
                account of events beyond our reasonable control, which may
                include, without limitation, denial-of-service attacks, strikes,
                shortages, riots, insurrection, fires, flood, storm, explosions,
                acts of God, war, terrorism, governmental action, labor
                conditions, earthquakes, material shortages, extraordinary
                Internet congestion or extraordinary connectivity issues
                experienced by telecommunications providers or connectivity to
                the Internet, or failure at ours / co-location facility(ies),
                exchange or market rulings, suspension of trading, civil
                commotion, failure of systems, failure of internet links or
                government/regulatory action etc. (each a “Force Majeure
                Event”). Upon the occurrence of a Force Majeure Event, we will
                be excused from any further performance of our obligations
                affected by the Force Majeure Event for so long as the event
                continues.
              </li>
              <li>
                The first time you login to the Platform, the web browser asks
                your permission to allow browser notification and alerts. Please
                allow this, else you won't be getting any alerts on your system
                when the strategy condition is met. The delivery of these alerts
                are subject to network conditions of the user, internet services
                and technical issues. Once the strategy is live and an alert is
                generated based on the conditions in the strategy, our systems
                attempt to deliver the alert to the user over the internet. By
                using this service, the user acknowledges they understand that
                the alerts' delivery is dependent on many factors such as the
                internet connection of the user, location, time of the day,
                server load, data availability etc. We recommend users to be
                logged in to the Platform, keep it open in their browser and
                maintain a fast uninterrupted internet connection to their
                devices to see the best alerts delivery.
                <br />
                Notwithstanding the above, we take no responsibility or provide
                any representation or warranty regarding our ability to send you
                alerts or their timely delivery or accuracy. You should not rely
                on the alerts for system-run strategic trading. The alerts may
                not be generated by the system or may not get delivered to you
                in time with accuracy. As mentioned earlier, you understand that
                once a program(s) is deployed in the live market, it requires
                your active involvement in reviewing the trades being carried
                out. You understand that volatility is the nature of the market
                and hence trade positioning requires user’s constant
                supervision. You take full responsibility for trades executed
                through any of the program(s) and are fully aware of and
                unconditionally own all the consequences that such trades may
                derive. The trade position created or squared off by the
                program(s) shall all be visible on your stock broker’s user
                interface. You MUST always rely on the information made
                available to you by your stock broker. Upon clicking on relevant
                submit / deployment / order buttons, based on users network
                speed, a network latency can be experienced and any rapid clicks
                on a button through same or different windows can lead to
                multiple order placements. Users take full responsibility on
                making sure the actions on the notifications are their own
                actions and are fully aware of their positions and strategy
                status when clicking on the buttons. Further, accuracy and
                suitability for you of any data or information, including alerts
                / trade signals, made available through or used in conjunction
                with the Platform should be independently verified by you before
                you rely on it.
                <p>
                  YOU ALONE ASSUME SOLE RESPONSIBILITY FOR ANY INVESTMENTS OR
                  DECISIONS YOU MAKE BASED ON THE PLATFORM OR ANY OF ITS
                  FEATURES (INCLUDING THE PROGRAM(S)). UNDER NO CIRCUMSTANCES
                  SHALL WE BE LIABLE FOR ANY LOSS OR DAMAGE YOU OR ANYONE ELSE
                  INCURS AS A RESULT OF: (A) ANY TRADING OR INVESTMENT ACTIVITY
                  THAT YOU OR ANYONE ELSE ENGAGES IN ON / THROUGH THE PLATFORM
                  OR (B) BASED ON ANY INFORMATION, DATA OR MATERIAL YOU ACCESS
                  ON/THROUGH THE PLATFORM OR (C) ANY INFORMATION, DATA OR
                  MATERIAL OR ALERT / TRADE SIGNAL YOU RECEIVE THROUGH THE
                  PLATFORM OR (D) AS A RESULT OF USE OF THE PLATFORM.
                </p>
              </li>
              <li>
                Intellectual Property Rights: We own and protect from illegal
                use the exclusive rights in our intellectual property, in
                particular: programming codes, design of the Platform, our
                databases; our trademarks, service marks and logos, and other
                content/features available on the Platform are not third-party
                intellectual property. No right in our intellectual property is
                granted to you, except for the non-exclusive, non-transferable,
                limited right and license (subject to these Terms of Use) to
                access the Platform as per the subscription made by you. We
                reserve the right to prosecute any infringers in court and/or
                through other authorities in order to protect our intellectual
                property. Any third-party trademarks or service marks (if any)
                used on the Platform are of their respective owners. In case of
                third-party adverts, promotions or other mentions on the
                Platform, we do not in any way recommend, endorse, approve or
                disapprove of any of them.
              </li>
            </ul>
          </div>
        </div>
        <div className="term-content">
          <div className="term-web-heading1">
            <h1>Prohibited Activities</h1>
          </div>
          <p>
            We use the term “Content” to mean entire or partial strategies,
            trading strategies, data transformations, data analysis and
            manipulation functions, tools, software, data, databases, text,
            messages, images, graphics, video files, audio files, ideas or any
            other information and materials. We use the term “Shared Content” to
            mean the Content (other than third party data) that we, you, or
            other users post in publicly accessible areas of the Platform. Third
            party data is subject to the terms and conditions of the provider of
            such data. Other than as provided at the end of this Section in
            respect of Shared Content, you acknowledge and agree that you will
            NOT:
          </p>
          <ul>
            <li>
              Copy, modify, publish, transmit, distribute, transfer or sell,
              create derivative works of, or in any way exploit, any of the
              Content accessible through the Platform not submitted or provided
              by you, including by use of any robot, spider, scraper, scripting,
              deep link or other similar automated data gathering or extraction
              tools, program, coded strategy or methodology, unless you obtain
              our prior written consent; use the Platform or any of features
              (including the program(s)) or services to advertise, market, sell,
              or otherwise promote any commercial enterprise that you own, are
              employed by or are otherwise compensated by, either directly or
              indirectly; use any engine, software, tool, agent or other device
              or mechanism to navigate or search the Platform, other than the
              search engines and agents available through the features and
              services offered at the Platform and other than generally
              available third party web browsers; copy, reverse engineer,
              reverse assemble, otherwise attempt to discover the source code,
              distribute, transmit, display, perform, reproduce, publish,
              license, create derivative works from, transfer or sell any
              information, software, products or services obtained through the
              Platform or any of features (including the program(s)) or
              services; access the Platform or use any of its features
              (including the program(s)) or services by any means other than
              through approved interfaces provided by us; transmit any Content
              that is unlawful, harmful, threatening, abusive, harassing,
              tortious, defamatory, vulgar, obscene, libelous, or otherwise
              objectionable or which may invade another's right of privacy or
              publicity;
            </li>
            <li>
              post or transmit any material that contains a virus, worm, Trojan
              horse, or any other contaminating or destructive feature; post or
              transmit information protected under any law, agreement or
              fiduciary relationship, including but not limited to proprietary
              or confidential information of others; use any of the Platform’s
              or any of its features’ (including the program(s)) or services’
              communications features in a manner that adversely affects the
              availability of its resources to other users; post or transmit any
              unsolicited advertising, promotional materials, "junk mail",
              "spam," "chain letters," "pyramid schemes" or any other form of
              solicitation or any non-resumé information such as opinions or
              notices, commercial or otherwise; access or use the Platform or
              any of features (including the program(s)) or services to
              intentionally or unintentionally violate any applicable local,
              state, national or international law, including, but not limited
              to, regulations promulgated under any such law; upload or transmit
              any material that infringes, violates or misappropriate any
              patent, trademark, service mark, trade secret, copyright or other
              proprietary rights of any third party or violates a third party’s
              right of privacy or publicity; manipulate or otherwise display or
              obstruct portions of the Platform and/or or any of its features
              (including the program(s)) or services by using framing or similar
              navigational technology; register, subscribe, attempt to register,
              attempt to subscribe, unsubscribe, or attempt to unsubscribe, any
              party for any of our products or services if you are not expressly
              authorized by such party to do so;
            </li>
            <li>
              use the Platform and/or or any of its features (including the
              program(s)) or services for any purpose that is unlawful or
              prohibited including, by these Terms of Use; use the Platform or
              any of its features (including the program(s)) or services in any
              manner that could damage, disable, overburden or impair our
              servers or networks, or interfere with any other user's use and
              enjoyment of the Platform and/or any of its features (including
              the program(s)) or services; attempt to gain unauthorized access
              to any of the Platform or any of its features (including the
              program(s)) or services, accounts, computer systems or networks
              connected to us through hacking, password mining, brute force or
              any other means; obtain or attempt to obtain any Content through
              any means not intentionally made available as Shared Content
              through the Platform or any of its features (including the
              program(s)) or services; or knowingly provide any Content that is
              false or inaccurate or will become false or inaccurate at any
              time.
            </li>
            <li>
              use of any third-party
              services/software/mechanisms/tool/plugins/code injections on the
              Platform or any of its features (including the program(s)) or
              services.
            </li>
          </ul>
          <span>
            THIS AGREEMENT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL
            BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASSNAME
            ACTIONS, AND ALSO LIMITS THE REMEDIES AVAILABLE TO YOU IN THE EVENT
            OF A DISPUTE.
          </span>
          <p>
            In the event of any dispute or difference between the you and us in
            respect of or concerning or connected with the interpretation or
            implementation or arising out of this agreement or your access and
            use of the Platform or any of the facilities (including the
            program(s)) and services provided therein, (a “Dispute”), then such
            Dispute shall in the first instance be resolved amicably by you and
            one of our representatives.
          </p>
          <p>
            In the event that these individual consultations fail to arrive at a
            joint decision on any Dispute within a period of 60 (sixty) days
            from the date of reference, then such Dispute shall be referred to
            and finally resolved by arbitration. The arbitration shall be
            conducted in accordance with the provisions of the Indian
            Arbitration and Conciliation Act, 1996 (as amended) (“Arbitration
            Act”) in force as on the date hereof. The seat and venue of
            arbitration, shall be in Mumbai, India. The arbitration shall be
            presided over by a single arbitrator by mutual consultation between
            the parties. If parties fail to appoint an arbitrator, the
            appointment shall be made, upon request by either Party, in
            accordance with the provisions of the Arbitration Act. Each Party to
            the Dispute shall co-operate in good faith to expedite (to the
            maximum extent practicable) the conduct of any arbitral proceedings
            commenced under this agreement). The language used in the arbitral
            proceedings shall be English. All documents submitted in connection
            with the proceedings shall be in the English language, or, if in
            another language, accompanied by an English translation. The
            decision of the arbitral tribunal shall be final and binding on all
            the Parties.
          </p>
          <p>
            This Agreement (and any non-contractual obligations arising out of
            or in connection with this Agreement) shall be governed by and
            construed in accordance with the Laws of India.
          </p>
        </div>
        <div className="term-content">
            <div className="term-web-heading">
                <h1>Contact Us</h1>
            </div>
                <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
                <ul>
                    <li>By email: support@qodrr.com</li>
                    <li>By phone number: +91 (983) (611)-3375</li>
                </ul>
        </div>
      </div>
    </div>
  );
}
