import axios from 'axios'

export default class Api {

    constructor(){

        this.axios = axios.create({
            crossDomain: true,
            method: 'post',
            responseType: 'json',
        })

        this.serverResponse = {
            status : true,
            data   :  '' ,
            error  : ''
        }
    }

    async signUp(formData){

            try {

                const serverResponse = await this.axios({
                    method: 'post',
                    url: '/trade-api/user/signup',
                    data: formData
                })

                this.serverResponse.status = true 
                this.serverResponse.data = await serverResponse.data

            } catch (error) {

                this.serverResponse.status = false 
                this.serverResponse.data   = 'error_occured'
                this.serverResponse.error  = error
            }

        return this.serverResponse
    }

    async verifyAccount(formData){

            try {

                const serverResponse = await this.axios({
                    method: 'post',
                    url: '/trade-api/verify-account',
                    data: formData
                })

                this.serverResponse.status = true 
                this.serverResponse.data = await serverResponse.data

            } catch (error) {

                this.serverResponse.status = false 
                this.serverResponse.data   = 'error_occured'
                this.serverResponse.error  = error
            }

        return this.serverResponse
    }

    async paymentStatus(formData){

            try {

                const serverResponse = await this.axios({
                    method: 'post',
                    url: '/trade-api/payment-status',
                    data: formData
                })

                this.serverResponse.status = true 
                this.serverResponse.data = await serverResponse.data

            } catch (error) {

                this.serverResponse.status = false 
                this.serverResponse.data   = 'error_occured'
                this.serverResponse.error  = error
            }

        return this.serverResponse
    }

    async submitContactUs(formData){

            try {

                const serverResponse = await this.axios({
                    method: 'post',
                    url: '/trade-api/enquiry',
                    data: formData
                })

                this.serverResponse.status = true 
                this.serverResponse.data  = await serverResponse.data

            } catch (error) {

                this.serverResponse.status = false 
                this.serverResponse.data   = 'error_occured'
                this.serverResponse.error  = error
            }

        return this.serverResponse
        
    }
}
 