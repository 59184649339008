import React from "react";
import { Link } from "react-router-dom";

import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import countries from "helpers/CountryCodes";

export default function SignUpForm({
  handleSubmit,
  error,
  loginError,
  formData,
  handleChange,
}) {
  const [countryValue, setCountryValue] = React.useState("+91");

  React.useEffect(() => {
    const countryDropdown = document.getElementsByClassName(
      "PhoneInputCountrySelect"
    );

    if (countryDropdown !== undefined) {
      const selectedCountry = countryDropdown[0].value;
      const getCountry = countries.filter(
        (country) => country.code === selectedCountry
      )[0];

      if (getCountry !== undefined) {
        const sliceTill = getCountry.dial_code.length;
        const currentCountry = countryValue === undefined ? "" : countryValue;
        const number = String(currentCountry).slice(
          sliceTill,
          currentCountry.length
        );

        if (number !== "")
          handleChange({ name: "number", value: countryValue });
      }
    }
  }, [countryValue, handleChange]);

  return (
    <div className="col-md-5">
      <div className="signup">
        <div className="web-heading">
          <h1>SIGN UP</h1>
        </div>
        <div className="signup-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="fullname">FULL NAME</label>
              <input
                type="text"
                className="form-control"
                name="fullname"
                id="fullname"
                onChange={(event) => {
                  handleChange(event.target);
                }}
                placeholder="Enter your full name"
              />
              {error(formData.fullname.alert)}
            </div>
            <div className="form-group">
              <label htmlFor="useremail">EMAIL ADDRESS</label>
              <input
                type="text"
                className="form-control"
                name="useremail"
                id="useremail"
                onChange={(event) => {
                  handleChange(event.target);
                }}
                placeholder="Enter your email"
              />
              {error(formData.useremail.alert)}
            </div>

            <div className="from-group">
              <input
                type="hidden"
                className="form-control"
                name="number"
                id="contactnumber"
                placeholder="+91"
              />
              <label htmlFor="contactnumber">CONTACT NUMBER</label>
              <PhoneInput
                international
                defaultCountry="IN"
                placeholder="Your Valid Phone Number"
                value={countryValue}
                name="number"
                onChange={setCountryValue}
                error={
                  countryValue
                    ? isValidPhoneNumber(countryValue)
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"
                }
              />
              {error(formData.number.alert)}
            </div>

            <div className="form-group">
              <br />
              <label htmlFor="username">USERNAME</label>
              <input
                type="text"
                className="form-control"
                name="username"
                id="username"
                onChange={(event) => {
                  handleChange(event.target);
                }}
                placeholder="Enter your username"
              />
              {error(formData.username.alert)}
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">PASSWORD</label>
              <input
                type="password"
                className="form-control"
                name="password"
                id="password"
                onChange={(event) => {
                  handleChange(event.target);
                }}
                autoComplete="true"
                placeholder="Enter your password"
              />
              {error(formData.password.alert)}
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="agree"
                onClick={(event) => {
                  if (event.target.checked)
                    handleChange({ name: "privacyPolicy", value: "true" });
                  else handleChange({ name: "privacyPolicy", value: "" });
                }}
              />
              <label className="form-check-label" htmlFor="agree">
                I agree to abide by Qodrr.com{" "}
                <Link to="/term-service.html">Terms of Service</Link> and its{" "}
                <Link to="/privacy-policy.html">Privacy Policy</Link>{" "}
              </label>
              {error(formData.privacyPolicy.alert)}
            </div>

            <div className="signup-button">
              <button className="btn btn-group sign">Sign Up</button>
            </div>
            <div className="form-content">
              <span className="span-one">
                Already have an account?{" "}
                <a href="https://app.qodrr.com/">
                  <span className="span-two">Sign in</span>
                </a>
              </span>
            </div>
            {error(loginError)}
          </form>
        </div>
      </div>
    </div>
  );
}
