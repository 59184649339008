const frequentlyAskedQuestions = [
    { 
        question : 'Is this platform fully automated?',
        answer   : 'Yes, it is.However, It interacts with different third party services, which required minimal intervention.', 
    },
    { 
        question : 'Do you provide trail period?',
        answer   : 'Yes, we do provide free plans for users to give them a feel of our platform.User should do signup with free plan selection to avail this facility.', 
    },
    { 
        question : 'Is my money secure with your platform?',
        answer   : 'We DO NOT hold your trading captial with us in any manor.Hence, your capital is still with your broker as usual.You would have to use your broker platform to transcat money.', 
    },
    { 
        question : 'Will I get a support in case of any technical issue?',
        answer   : 'We have well documented FAQ section. It resolves most of the queries for user.Even if it is technical query.However, we do provide some extended support for our PRO customers.In case of any unanswered query through FAQ section, feel free to get in touch with us through our contact us service.', 
    }
];

export default frequentlyAskedQuestions;