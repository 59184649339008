import React, { Component } from 'react'
import store from 'store'

// -------------  import filxed components ------------- 

import Navbar from 'components/navbar/Navbar.jsx'
import Footer from 'components/footer/Footer.jsx'

// -------------  import page content --------------------

import MarketDescription from 'components/marketPlaceDetails/MarketDescription.jsx'
import MarketDetails  from 'components/marketPlaceDetails/MarketDetails.jsx'

// -------------  import icons ------------- 

import Like  from 'images/heart.png'
import Share from 'images/share.png'

// -------------  import third party libraries --------------------

import WOW from 'wowjs';  /** @WOWJS for animation @version 1.1.3 **/

export default class MarketPlaceDetails extends Component {

    constructor(props){
        super(props)
        this.wow  = new WOW.WOW({  live: false  }).init();
        this.data = store.get('price-card-details')

        if(this.data === undefined) this.props.history.push('/')
        
        window.scroll({top: 0, behavior: "smooth"});
    }

    subscribe() { 
        store.set('plan_data',this.data.minCapital,new Date().getTime() + (10*60*1000))
        this.props.history.push("/signup.html") //Redirect user to signup page ounce user select a plan
    }

    render() {
        return (
            <div className="wrapper">
                <section>
                    <Navbar>
                        <Navbar.Header></Navbar.Header>
                        <Navbar.Nav></Navbar.Nav>
                    </Navbar>
                </section>

                <section className="service-bg">
                    <div className="m-place">
                        <div className="container">
                            <div className="mp-place">
                                
                                <div className="web-heading">
                                    <h1>Marketplace</h1>
                                </div>

                                <div className="search">
                                    <input type="text" className="searchTerm" placeholder="Search..."/>
                                    <button type="submit" className="searchButton">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                             
                                
                                <div className="marketplace-card">
                                    <div className="card">
                                        <div className="markets-content">
                                            
                                            <div className="row">
                                                <div className="market-heading col-md-9">
                                                    <h1>{ this.data.heading }</h1>
                                                </div>
                                                <div className=" icon-market col-md-3">
                                                    <div className="market-icon">
                                                        <button className="btn btn-group"><img src={Like}  alt="..." /></button>
                                                        <button className="btn btn-group"><img src={Share} alt="..." /></button>
                                                    </div>
                                                </div>
                                            </div>

                                            <p>{ this.data.subheading }</p>

                                            <MarketDescription></MarketDescription>

                                            <MarketDetails data={this.data}></MarketDetails> 
                                    
                                            <div className="subscribe-btn">
                                                <button onClick={ () => this.subscribe()} className="btn btn-group subo">Subscribe</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <Footer></Footer>
                </section>
            </div>
        )
    }
}
