import React from 'react'

import Illustration from 'images/Algo strategy guidance Illustration.png'


export default function AlgoStrategyGuidance() {
    return (
        <div className="row mt-2 mb-2">

            <div className="col-md-6">
                <div className="service-img1">
                    <img src={Illustration} alt="..." />
                </div>
            </div>
            <div className="col-md-6 mt-auto mb-auto" >
                <div className="service-content1">
                    <div className="service-heading">
                        <h1>Algo Strategy Guidance</h1>
                    </div>
                    <div className="service-content">
                        <p>Our premium service includes a detailed<br />
                            guidance to build your own trading strategy.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
