import React from 'react'

export default function DisclaimerContent() {
    return (
        <div className="container">
            <div className="web-heading-ab wow slideInRight" data-wow-duration="1s" data-wow-delay="1s">
                <h1>Disclaimer</h1><br/>
                <span>Last updated: April 1st, 2021</span>
                <p>
                    Using the Platform or the features available on it (including the program(s)) presents several different types of risks, a few examples of which have been summarized below and elsewhere in this document. You should read and understand these risks before you use the Platform or the features available on it (including the program(s)). The list below is only indicative and not exhaustive.
                </p>
            </div>
        </div>
    )
}
