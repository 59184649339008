
import ellipseTwo    from 'icons/home/question/Ellipse 2.png'
import ellipseThree  from 'icons/home/question/Ellipse 3.png'
import question  from 'icons/home/question/why choose us illustration.png'
import { NavLink } from 'react-router-dom';


const ChooseUsSection = () => {
    return (
        <section>
            <div className="choose-img-bg-left">
                <img src={ellipseTwo} alt="..." />
            </div>
            <div className="choose-img-bg-right">
                <img src={ellipseThree} alt="..." />
            </div>
            <div className="container">
                <div className="choose wow wobble " data-wow-duration="1s" data-wow-delay="1s">
                    <div className="web-heading">
                        <h1>Why Choose Us ?</h1>
                    </div>
                    <div className="choose-content">
                        <p>Qodrr.com crew brings to you an unbeatable power of Technicals combined with
                            decades of Stock Broking experience & latest high-end Technology to drive your trades.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="choose-img">
                                <img src={question} alt="..." />
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="counts">
                                <div className="count">
                                    <h2>135+</h2>
                                    <span>Active Customers</span>
                                </div>
                                <div className="count">
                                    <h2>50+</h2>
                                    <span>Years Commulative Experience</span>
                                </div>
                                <div className="count">
                                    <h2>61728+</h2>
                                    <span>Order Executed</span>
                                </div>
                                <NavLink to="/about-us.html" className="learn-btn btn btn-group">Learn More</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChooseUsSection;