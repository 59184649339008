import React from 'react'

export default function MarketDescription() {
    return (
        <div className="market-description">
            <div className="description-heading">
                <h4>Strategy description</h4>
                <p className="desc"> This strategy is introduced by Market Star. Market Star is a well known
                    brand with 15000+
                    subscribers in telegram since 3 years. Index Premium Eater strategy is
                    based on options selling.
                    As per our criteria we choose a banknifty and nifty short straddle. The
                    strategy has max loss 3000
                    and max profit of 2000 per counter. On exiry day targets booked at 1500
                    profit. After each exit the
                    strategy reactivates in 5 mins and enters new trade. The strategy starts
                    at 9:16 AM and continue
                    till 3 PM. The startegy works at all type of market conditions. Whoever
                    wants a systematic trading
                    with consistent return your search ends here.
                </p>
                <p>
                    <span>Telegram Support :</span>
                    <a href="https://t.me/Market_Star_Algo_Live">https://t.me/Market_Star_Algo_Live</a>
                </p>
                <p>
                    <span>Contact :</span>
                    <a href="https://t.me/MarketStar_Admin"> https://t.me/MarketStar_Admin</a> or
                    <a href="https://t.me/Trading_Inquiry">https://t.me/Trading_Inquiry</a>
                </p>
                <p>
                    <span>Strategy Explanation Video :</span>
                    <a href="https://www.youtube.com/watch?v=19t5aq0kgNc">https://www.youtube.com/watch?v=19t5aq0kgNc</a>
                </p>
                <p className="special">
                    <span>Note :</span>Do not subscribe the strategy without watching above
                    video explanation. Since this is a
                    dynamic strategy with many repair conditions, for sure PNL differences
                    will be there user to user.
                </p>
                <p><span>User Registration Link (Compulsory) :</span>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSciES6FHkqmTwXCcW-3MLLhJvJ9C6-hlwdTRoBAIQnA">
                        https://docs.google.com/forms/d/e/1FAIpQLSciES6FHkqmTwXCcW-3MLLhJvJ9C6-hlwdTRoBAIQnA
                    </a>
                </p>
                <div className="last-content">
                    <span>This strategy if used with Index OTM Hedge strategy then will need
                        only 200000 capital. Without
                        hedge capital needed 320000..
                    </span>
                </div>
                <span className="last-one">INDEX OTM Hedge Link</span>
            </div>
        </div>
    )
}
