import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import "styles/style.css";
import "styles/animate.css";
import 'styles/pagination.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "font-awesome/css/font-awesome.min.css";

// -------------- import react pages --------------

import Home               from "pages/Home";
import Affiliate          from "pages/Affiliate";
import Faq                from "pages/Faq";
import MarketPlace        from "pages/MarketPlace";
import MarketPlaceDetails from "pages/MarketPlaceDetails";
import MarketPlacePrices  from "pages/MarketPlacePrices";
import Price              from "pages/Price";
import About              from "pages/About";
import ContactUs          from "pages/ContactUs";
import Services           from "pages/Services";
import ThankYou           from "pages/ThankYou";
import PageNotFound       from "pages/PageNotFound";
import SignUp             from "pages/SignUp";
import TermsOfService     from "pages/TermsOfService";
import Disclaimer         from "pages/Disclaimer";
import PrivacyPolicy      from "pages/PrivacyPolicy";
import PaymentResponse    from "pages/PaymentResponse"
import VerifyPage         from "pages/VerifyPage";
 
class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <Route exact name="about"              path="/about-us.html"                 component={About} />
            <Route exact name="services"           path="/our-services.html"              component={Services}/>
            <Route exact name="price"              path="/pricing.html"                 component={Price} />
            <Route exact name="marketPlace"        path="/market-place.html"          component={MarketPlace} />
            <Route exact name="MarketPlaceDetails" path="/market-place-details.html"  component={MarketPlaceDetails} />
            <Route exact name="MarketPlacePrices"  path="/market-place-prices.html"   component={MarketPlacePrices}/>
            <Route exact name="affiliate"          path="/affiliate.html"             component={Affiliate} />
            <Route exact name="home"               path="/faq.html"                   component={Faq} />
            <Route exact name="contact"            path="/contact-us.html"            component={ContactUs}/>
            <Route exact name="signup"             path="/signup.html"                component={SignUp} />
            <Route exact name="thankyou"           path="/thankyou.html"              component={ThankYou}/>
            <Route exact name="page-not-found"     path="/page-not-found.html"             component={PageNotFound} />
            <Route exact name="term-service"       path="/term-service.html"          component={TermsOfService}  />
            <Route exact name="disclaimer"         path="/disclaimer.html"            component={Disclaimer} />
            <Route exact name="privacy-policy"     path="/privacy-policy.html"        component={PrivacyPolicy}    />
            <Route exact name="payment-response"   path="/payment-response.html"      component={PaymentResponse} />
            <Route exact name="verify-page"        path="/verify-account.html"        component={VerifyPage} />
            
            <Route path="/">
                <Home></Home>
            </Route>
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
