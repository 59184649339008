import React from 'react'
import Illustration from 'images/Expert advice ill.png'

export default function ExpertAdvice() {
    return (
        <div className="row mt-2 mb-2">
            <div className="col-md-6">
                <div className="service-img1">
                    <img src={Illustration} alt="..." />
                </div>
            </div>
            <div className="col-md-6 mt-auto mb-auto" >
                <div className="service-content1">
                    <div className="service-heading">
                        <h1>Expert Advice</h1>
                    </div>
                    <div className="service-content">
                        <p>Connect with us to get export advices about <br />
                            markets. Enhance<br />
                            your knowledge with our expert Analysts.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
